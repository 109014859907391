<div>
  <wg-ssp-password-authentication
    [passwordAuthenticationDialog]="mustOpenMobileTokenActivationDialog && !isUserAuthenticated"
    [title]="title"
    [accountId]="accountId"
    [username]="username"
    (closePasswordAuthenticationDialog)="closeDialog()"
    (passwordAuthenticated)="onPasswordAuthenticated($event)">
  </wg-ssp-password-authentication>

  <wg-dialog class="custom-mobile-token-activation-dialog allow-overflow" [showDialog]="mustOpenMobileTokenActivationDialog && isUserAuthenticated" (dialogClosedEvent)="closeDialog()">
    <div dialog-title class="dialog-title">{{ messageTranslateKeys.mobile_token_activation_title | translate }}</div>

    <div dialog-content>
      <wg-ssp-qrcode-activation
        [accountId]="accountId"
        [exhibitionType]="ExhibitionType.DIALOG">
      </wg-ssp-qrcode-activation>
    </div>
  </wg-dialog>
</div>
