import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, ResolveFn, Router } from '@angular/router';

import { IDP_LOGIN } from '../authentication-form/authentication.model';
import { BrowserService, PATH } from '../shared';

export const sspTokenPermissionActivate: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  return inject(SspTokenPermissionResolver).canActivate(route);
};

export const sspTokenPermissionResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  return inject(SspTokenPermissionResolver).resolve(route);
};

@Injectable({ providedIn: 'root' })
export class SspTokenPermissionResolver {

  constructor(
    private browserService: BrowserService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.router.url.includes(PATH.VALIDATE)) {
      this.router.navigate(['/', route.params.accountAlias]);
      return false;
    }

    const authentication = this.browserService.getItemLocalStorage(`${IDP_LOGIN}_authentication`);
    if (authentication) {
      return true;
    } else {
      this.router.navigate([PATH.ERROR]);
      return false;
    }
  }
}
