import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoaderService } from '@watchguard/wg-loader';
import { WgNotificationsService } from '@watchguard/wg-notifications';
import { WgSearchComponent } from '@watchguard/wg-search';

import { Authentication } from '../authentication-form/authentication.model';
import { Branding } from '../branding/branding.model';
import { BrandingService } from '../branding/branding.service';
import { SspToggleFeatureResponse, SspConfigRequest } from '../self-service-portal/ssp-token.model';
import { SspTokenService } from '../self-service-portal/ssp-token.service';
import { AutoUnsubscribe, BrowserService, DEFAULT_IMAGE, MESSAGE_TRANSLATE_KEYS } from '../shared';

import { Application } from './applications.model';
import { ApplicationsService } from './applications.service';
import { CONNECTION_ERROR_STATUS } from './applications.constants';

@Component({
  selector: 'wg-login-resources',
  templateUrl: 'applications.component.html',
  styleUrls: ['applications.component.scss']
})
@AutoUnsubscribe()
export class ApplicationsComponent implements OnInit, OnDestroy, AfterViewInit {
  brandingSubscription: Subscription;
  routeSubscription: Subscription;
  sspSubscription: Subscription;

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  applications: Application[];
  allApplications: Application[];
  authentication: Authentication;
  hasToShowUserMenu: boolean;
  hasToShowChangePasswordMenu: boolean;
  username: string;
  fullName: string;
  email: string;
  accountAlias: string;
  accountId: string;
  isEditPasswordDialogOpen: boolean;
  isMobileTokenActivationDialogOpen: boolean;
  isHardwareTokenActivationDialogOpen: boolean;
  isSspEnabled: boolean;
  interval: any;
  headerImageUrl: string;
  searchValue: string;

  @HostBinding('style.background-image') backgroundImage = '';
  @HostBinding('style.background-color') backgroundColor = '';

  @ViewChild(WgSearchComponent) searchComponent: WgSearchComponent;

  constructor(
    private applicationsService: ApplicationsService,
    private activatedRoute: ActivatedRoute,
    private brandingService: BrandingService,
    private browserService: BrowserService,
    private notificationService: WgNotificationsService,
    private loaderService: LoaderService,
    private sspTokenService: SspTokenService
  ) {
    this.configBranding();
  }

  ngOnInit() {
    this.loaderService.showLoading();
    this.accountAlias = this.applicationsService.getAccountAlias();
    this.authentication = this.applicationsService.getAuthentication();
    this.accountId = this.authentication?.accountId;
    this.username = this.authentication?.user?.username;
    this.fullName = this.authentication?.user?.fullName;
    this.email = this.authentication?.user?.email;

    this.sspSubscription = this.sspTokenService.getSspToggleFeature(this.authentication.accountId, this.createSspConfigRequest())
      .subscribe((sspToggleFeatureResponse: SspToggleFeatureResponse) => this.isSspEnabled = sspToggleFeatureResponse.preference);

    this.routeSubscription = this.activatedRoute.data
      .subscribe((data: any) => {
        this.applications = this.allApplications = data.applications;
        this.loaderService.hideLoading();
      });

    this.setLogoutTimer();
  }

  ngAfterViewInit() {
    /* The autofocus directive only works for input elements
       Once we update the IDP to user Angular 13, we will be able
       to update the autofocus directive (on ngCore repo),
       to verify if the input element is inside other elements. */
    this.searchComponent.searchbox.nativeElement.focus();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  openApplication(resourceUrl: string) {
    if (this.applicationsService.isLogged(this.accountAlias)) {
      window.open(resourceUrl);
    }
  }

  logout() {
    this.hasToShowUserMenu = false;
    this.hasToShowChangePasswordMenu = false;

    this.applicationsService.logout(this.authentication).subscribe({
      next: () => {
        this.applicationsService.cleanCookieAndRedirecToInitialPage(this.accountAlias, this.authentication.accountId)
      },
      error: (error: HttpErrorResponse) => {
        this.handleLogoutError(error)
      }
    });
  }

  searchTermChange(filter: string) {
    if (filter) {
      filter = this.validateSpecialRegexChar(filter);
      this.applications = this.allApplications.filter((application) =>
        application.name.search(new RegExp(filter, 'i')) !== -1
      );
    } else {
      this.applications = this.allApplications;
    }
  }

  openChangePasswordDialog(event: Event) {
    this.isEditPasswordDialogOpen = true;
    this.toggleChangePasswordMenu(event);
  }

  openMobileTokenActivationDialog(event: Event) {
    this.isMobileTokenActivationDialogOpen = true;
    this.toggleUserMenu(event);
  }

  openHardwareTokenActivationDialog(event: Event) {
    this.isHardwareTokenActivationDialogOpen = true;
    this.toggleUserMenu(event);
  }

  closeEditPasswordDialog() {
    this.isEditPasswordDialogOpen = false;
  }

  closeMobileTokenActivationDialog() {
    this.isMobileTokenActivationDialogOpen = false;
  }

  closeHardwareTokenActivationDialog() {
    this.isHardwareTokenActivationDialogOpen = false;
  }

  clickOutside(event: any) {
    if (event.close) {
      if (this.hasToShowChangePasswordMenu) {
        this.hasToShowChangePasswordMenu = false;
      }

      if (this.hasToShowUserMenu) {
        this.hasToShowUserMenu = false;
      }
    }
  }

  toggleUserMenu(event: Event) {
    event.stopPropagation();
    this.hasToShowUserMenu = !this.hasToShowUserMenu;
    this.hasToShowChangePasswordMenu = false;
  }

  toggleChangePasswordMenu(event: Event) {
    event.stopPropagation();
    this.hasToShowChangePasswordMenu = !this.hasToShowChangePasswordMenu;
    this.hasToShowUserMenu = false;
  }

  loadDefaultHeaderImage() {
    this.headerImageUrl = DEFAULT_IMAGE.HEADER;
  }

  onApplicationImageError(event: Event) {
    event.target['src'] = this.headerImageUrl;
  }

  private setLogoutTimer() {
    this.interval = setInterval(() => {
      const isValid = this.browserService.getAuthenticationCookies('isAuthenticationValid');
      if (!isValid) {
        this.applicationsService.cleanCookieAndRedirecToInitialPage(this.accountAlias, this.authentication.accountId);
      }
    }, 1000);
  }

  private configBranding() {
    this.brandingSubscription = this.brandingService.branding
      .subscribe((branding: Branding) => {
        // Logo
        this.headerImageUrl = branding.smallImageUrl;

        // Background
        this.setBgImage(branding.backgroundImageUrl);
        this.brandingService
          .loadImage(branding.backgroundImageUrl)
          .subscribe({
            error: () => {
              this.setBgColor(DEFAULT_IMAGE.BACKGROUND);
            }
          });
      });
  }

  private setBgImage(imageUrl: string) {
    this.backgroundImage = `url(${imageUrl})`;
  }

  private setBgColor(color: string) {
    this.backgroundColor = color;
  }

  private validateSpecialRegexChar(filter: string) {
    const specialRegexChar = /[.*+?^${}()|[\]\\]/g;
    return filter.replace(specialRegexChar, '\\$&');
  }

  private handleLogoutError(error: HttpErrorResponse) {
    const isAConnetionProblem = error.status === CONNECTION_ERROR_STATUS;
    const message = isAConnetionProblem ? 'auth_sso_resources_logout_connection_error' : 'auth_sso_resources_logout_error';

    this.notificationService.error(message, true, { autohide: true });
  }

  private createSspConfigRequest(): SspConfigRequest {
    return {
      login: this.authentication.user.username
    }
  }
}
