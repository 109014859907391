<form id="form-username" #loginForm="ngForm" (ngSubmit)="getAuthenticationPolicy(loginForm.valid)" *ngIf="!isLoading">
    <div *ngIf="loginInvalid" class="error-message">
        <wg-icon name="attention"></wg-icon>
        <span class="align-middle" [translate]="errorMessage"></span>
    </div>
    <div *ngIf="sessionInvalid" class="alert alert-warning" role="alert">
      <wg-icon name="attention"></wg-icon>
      <span [translate]="errorMessage"></span>
    </div>
    <div class="form-group custom-form-group required">
        <label>{{ messageTranslateKeys.user_name_or_email_label | translate }}</label>
        <input autofocus type="text" name="loginForm.username" id="form-username-input" XSSPatternValidator minlength="2" maxlength="255" #username="ngModel"
            [(ngModel)]="user.username" (ngModelChange)="cleanErrorMessage()" required class="input-block-level form-control"
            placeholder="{{ messageTranslateKeys.user_name_or_email_label | translate }}">
        <show-errors [control]="username" keySuffix="username_or_email"></show-errors>
    </div>
    <div class="form-actions">
        <button id="form-username-submit" class="btn btn-primary login-btn" type="submit" [disabled]="loginForm.invalid" [translate]=" messageTranslateKeys.next"></button>
    </div>
</form>
