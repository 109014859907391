import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WgDialogModule } from '@watchguard/wg-dialog';
import { WgAutofocusModule, WgOnlyNumberModule } from '@watchguard/wg-directives';
import { WgHideShowPasswordModule } from '@watchguard/wg-hide-show-password';
import { WgIconModule } from '@watchguard/wg-icon';
import { ValidatorModule } from '@watchguard/wg-validator';
import { WgSearchModule } from '@watchguard/wg-search';
import { WgInfoPopoverModule } from '@watchguard/wg-info-popover';
import { CookieService } from 'ngx-cookie-service';

import { ForgetAuthenticatorAlertComponent } from '../login/forget-authenticator-alert/forget-authenticator-alert.component';
import {
  ForgetAuthenticatorFormComponent,
} from '../login/forget-authenticator/forget-authentication-form/forget-authenticator-form.component';
import { ForgetAuthenticatorComponent } from '../login/forget-authenticator/forget-authenticator.component';
import { BrowserService } from './browser.service';
import { ErrorHandlerService } from './error-handler.service';
import { GeolocationService } from './geolocation.service';
import { NavigatorInformationService } from './navigator-information.service';
import { SamlFormComponent } from './saml-form.component';
import { SamlFormService } from './saml-form.service';
import { WgSubmitOnEnterDirective } from './wg-submit-on-enter.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    WgDialogModule,
    ValidatorModule,
    WgAutofocusModule,
    WgHideShowPasswordModule,
    WgSearchModule,
    WgIconModule,
    WgOnlyNumberModule,
    WgInfoPopoverModule
  ],
  declarations: [
    SamlFormComponent,
    ForgetAuthenticatorComponent,
    ForgetAuthenticatorFormComponent,
    ForgetAuthenticatorAlertComponent,
    WgSubmitOnEnterDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    WgDialogModule,
    ValidatorModule,
    WgAutofocusModule,
    WgHideShowPasswordModule,
    WgSearchModule,
    WgInfoPopoverModule,
    WgIconModule,
    WgOnlyNumberModule,
    SamlFormComponent,
    ForgetAuthenticatorComponent,
    ForgetAuthenticatorFormComponent,
    ForgetAuthenticatorAlertComponent,
    WgSubmitOnEnterDirective
  ],
  providers: [
    ErrorHandlerService,
    BrowserService,
    CookieService,
    SamlFormService,
    NavigatorInformationService,
    GeolocationService
  ]
})
export class SharedModule { }
