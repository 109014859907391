import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { NEVER, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { URL_ACCOUNT_INFO } from '../config/app-settings';
import { PATH } from '../shared';
import { AccountInfo } from './account-info.model';

export const accountInfoResolver: ResolveFn<AccountInfo> = (route: ActivatedRouteSnapshot) => {
  return inject(AccountInfoResolver).resolve(route);
};

@Injectable()
export class AccountInfoResolver {
  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<AccountInfo> {
    const accountAlias = route.paramMap.get('accountAlias');
    if (accountAlias && accountAlias !== PATH.SP_INITIATED) {
      this.loaderService.showLoading();
      return this.http
        .get<AccountInfo>(URL_ACCOUNT_INFO.replace(':accountAlias', accountAlias))
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              this.router.navigate([PATH.ERROR]);
              return NEVER;
            }
          }),
          finalize(() => this.loaderService.hideLoading())
        );
    } else {
      return of(null);
    }
  }
}
