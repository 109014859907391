import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SPAuthenticationService } from './sp-authentication.service';
import { SamlApplication, SamlApplicationResponse } from '../applications/applications.model';
import { Authentication, AuthenticationPolicy } from '../authentication-form/authentication.model';
import { BrowserService, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS, PATH, SamlFormService } from '../shared';
import { ErrorHandlerService } from '../shared/error-handler.service';
import { SessionService } from '../session/session.service';

@Component({
  selector: 'wg-sp-authentication-component',
  template: ``
})
export class SPAuthenticationComponent implements OnInit {

  authentication: Authentication;

  constructor(
    private activatedRoute: ActivatedRoute,
    private browserService: BrowserService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private samlFormService: SamlFormService,
    private sessionService: SessionService,
    private spAuthenticationService: SPAuthenticationService
  ) { }

  ngOnInit() {
    this.authentication = this.activatedRoute.snapshot.data.authentication;
    if (this.authentication) {
      this.openServiceProvider();
    } else {
      this.redirectToErrorPage(ERROR_PREFIX.open_service_provder);
    }
  }

  private openServiceProvider() {
    this.spAuthenticationService
      .serviceProvider(this.authentication).subscribe({
      next: (response: any) => {
        this.handleServiceProviderResponse(response)
      },
      error: (error: HttpErrorResponse) => {
        this.redirectToErrorPage(ERROR_PREFIX.open_service_provder, error);
      }
    });
  }

  private handleServiceProviderResponse(response: any) {
    const hasToUpgradeSession = this.sessionService.hasToUpgradeSession(response);
    const isForgotTokenActivated = this.sessionService.isForgotTokenActivated(response);

    if (response && response.samlResponse) {
      this.samlFormService.samlSubmitForm(response);
    } else if (hasToUpgradeSession || isForgotTokenActivated) {
      this.redirectToSignin(response);
    } else {
      this.browserService.setError(MESSAGE_TRANSLATE_KEYS.error_open_sp);
      this.router.navigate([PATH.ERROR]);
    }
  }

  private redirectToSignin(authenticationPolicy: AuthenticationPolicy) {
    const application: SamlApplicationResponse = {
      resourceName: this.authentication.saml.selectedSp,
      applicationType: this.activatedRoute.snapshot.queryParams.applicationType
    };

    if (this.activatedRoute.snapshot.queryParams.applicationLogoId) {
      application.imageId = this.activatedRoute.snapshot.queryParams.applicationLogoId;
    }

    const samlApplication = new SamlApplication(application);

    this.sessionService.redirectToSignin(this.authentication, authenticationPolicy, samlApplication);
  }

  private redirectToErrorPage(requestType: string, httpErrorResponse?: HttpErrorResponse) {
    if (this.authentication && this.authentication.saml && this.authentication.saml.selectedSp) {
      this.browserService.deleteItemLocalStorage(`${this.authentication.saml.selectedSp}_serviceProviderLogo`);
      const errorMessage = this.errorHandlerService.getMessageError(requestType, httpErrorResponse);
      this.browserService.setError(errorMessage);
    } else {
      this.browserService.setError(MESSAGE_TRANSLATE_KEYS.error_open_sp);
    }
    this.router.navigate([PATH.ERROR]);
  }
}
