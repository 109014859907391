import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { finalize, switchMap, take, tap } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { HeaderService } from '../../login/header/header.service';
import {
  AutoUnsubscribe,
  BrowserService,
  ERROR_PREFIX,
  ErrorHandlerService,
  NavigatorInformationService,
  PATH,
  SamlFormService,
} from '../../shared/';
import { ApplicationListService } from '../../applications/applications-list/application-list.service';
import { AuthenticationPolicyService } from '../authentication-policy.service';
import { AuthenticationComponent } from '../authentication.component';
import { AuthenticationPolicy, OTP, PASSWORD, PUSH, QR_CODE } from '../authentication.model';
import { OTPService } from '../otp/otp.service';
import { QRCodeService } from '../qrcode/qrcode.service';
import { ValidateService } from './validate.service';

@Component({
  selector: 'wg-login-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
@AutoUnsubscribe()
export class ValidateComponent extends AuthenticationComponent implements OnInit, OnDestroy {

  password: string;
  authenticationSelected: string;
  authenticationPolicyLabels: Array<any>;
  forgetPassword: boolean;

  constructor(
    public errorHandleService: ErrorHandlerService,
    private loaderService: LoaderService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public samlFormService: SamlFormService,
    public browserService: BrowserService,
    public otpService: OTPService,
    public qrCodeService: QRCodeService,
    public validateService: ValidateService,
    private navigatorInformationService: NavigatorInformationService,
    public headerService: HeaderService,
    public applicationListService: ApplicationListService,
    private authenticationPolicyService: AuthenticationPolicyService
  ) {
    super(activatedRoute, router, errorHandleService, headerService, browserService, samlFormService, applicationListService);
  }

  ngOnInit() {
    this.serviceProvider = this.validateService.getServiceProvider();
    this.serviceProviderLogo = this.validateService.getServiceProviderLogo();
    this.authentication = this.validateService.getAuthentication();
    this.authenticationPolicy = this.validateService.getAuthenticationPolicy();
    this.authenticationPolicyLabels = this.validateService.getAuthenticationPolicyLabels();
    this.loginByPassword = this.authenticationPolicy.password;
    this.headerService.headerSubmit({
      title: this.messageTranslateKeys.choose_type_authentication,
      serviceProviderLogo: this.serviceProviderLogo,
      subtitle: this.authentication.user.username,
    });
  }

  ngOnDestroy() {/* It should be here to use the AutoUnsubscribe decorator */ }

  onSubmit(form: any, authentication?: string) {
    if (form.valid) {
      this.password = form.password;
      switch (authentication) {
        case PASSWORD:
          this.passwordLogin();
          break;
        case PUSH:
          this.pushLogin();
          break;
        case OTP:
          this.optLogin();
          break;
        case QR_CODE:
          this.qrCodeLogin();
          break;
      }
    }
  }

  // TODO: Validate if it is really necessary
  updateAuthenticationPolicy() {
    this.authenticationPolicyService
      .fetchAuthenticationPolicy(this.authentication, this.serviceProvider)
      .subscribe({
        next: (authenticationPolicy: AuthenticationPolicy) => {
          this.authenticationPolicy = authenticationPolicy;
          this.validateService.setAuthenticationPolicy(authenticationPolicy);
        },
        error: (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_policy, error)
      });
  }

  private passwordLogin() {
    this.loaderService.showLoading();
    this.setAuthenticationUser(false, this.password, null, false);
    this.validateService
      .login(this.authentication, this.authenticationPolicy.isUpgrade).pipe(
        finalize(() => this.loaderService.hideLoading())
      )
      .subscribe({
        next: (response: any) => this.handleLoginSuccess(response),
        error: (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_password, error)
      });
  }

  private pushLogin() {

    let pushRequestSubscription: Subscription;

    const finishPush = () => {
      pushRequestSubscription.unsubscribe();
      this.authenticationPolicyLabels[0].label = this.messageTranslateKeys.send_push;
    };

    const verifyPushResult = () => {
      pushRequestSubscription = interval(3000)
        .pipe(
          take(20),
          switchMap(() => this.validateService.pushResult(this.authentication, this.authenticationPolicy.isUpgrade)),
          finalize(() => this.loaderService.hideLoading())
        )
        .subscribe({
          next: (response: any) => {
            if (!response.errors) {
              finishPush();
              this.handleLoginSuccess(response);
            }
          },
          error: (error: HttpErrorResponse) => {
            finishPush();
            this.errorHandle(ERROR_PREFIX.authentication_push, error);
          },
          complete: () => {
            this.errorMessage = ERROR_PREFIX.push_result_timeout;
            this.loginInvalid = true;
            finishPush();
          }
        });
    };

    this.setAuthenticationUser(true, this.password, null, false);
    const authenticationObj = this.getAuthenticationObj(this.navigatorInformationService.getTransactionData());
    this.loaderService.showLoading();

    this.validateService
      .login(authenticationObj, this.authenticationPolicy.isUpgrade)
      .pipe(
        tap((pushTransactionId) => this.authentication.user.authentication = pushTransactionId),
        tap(() => this.authenticationPolicyLabels[0].label = this.messageTranslateKeys.sent_push),
      )
      .subscribe({
        next: () => verifyPushResult(),
        error: (error: HttpErrorResponse) => {
          this.errorHandle(ERROR_PREFIX.authentication_push, error);
          this.loaderService.hideLoading();
        }
      });
  }

  private optLogin() {
    if (this.loginByPassword) {
      this.otpService.setPassword(this.password);
    }
    this.router.navigate(['..', PATH.OTP], { relativeTo: this.activatedRoute, queryParams: { serviceProvider: this.serviceProvider } });
  }

  private qrCodeLogin() {
    if (this.loginByPassword) {
      this.qrCodeService.setPassword(this.password);
    }
    this.router.navigate(['..', PATH.QR_CODE], { relativeTo: this.activatedRoute, queryParams: { serviceProvider: this.serviceProvider } });
  }
}
