<wg-dialog
  *ngIf="editPasswordDialog"
  class="dialog"
  class="custom-change-password-dialog"
  [showDialog]="true"
  (dialogClosedEvent)="closeDialog()">
  <div dialog-title>{{messageTranslateKeys.change_password | translate}} </div>
  <div dialog-content>
    <div *ngIf="errorMessage" class="error-message text-center">
      <wg-icon name="attention"></wg-icon>
      <span class="align-middle">{{ errorMessage | translate }}</span>
    </div>
    <form #passwordForm="ngForm">
      <div class="form-group custom-form-group">
        <label for="current-password" [translate]="messageTranslateKeys.current_password"></label>
        <wg-hide-show-password>
          <input
            #password="ngModel"
            name="password"
            type="password"
            class="input-block-level"
            XSSPatternValidator
            minlength="6"
            maxlength="50"
            placeholder="{{messageTranslateKeys.enter_current_password | translate}}"
            autocomplete="current-password"
            required
            [(ngModel)]="changePassword.password"
            (ngModelChange)="cleanErrorMessage()">
        </wg-hide-show-password>
        <show-errors [control]="password" keySuffix="password"></show-errors>
      </div>
      <div class="form-group custom-form-group">
        <label for="new-password" [translate]="messageTranslateKeys.new_password"></label>
        <wg-hide-show-password>
          <input
            #newPassword="ngModel"
            name="newPassword"
            type="password"
            class="input-block-level"
            validateEqual="confirmPassword"
            reverse="true"
            minlength="6"
            maxlength="50"
            XSSPatternValidator
            required
            placeholder="{{ messageTranslateKeys.enter_new_password | translate}}"
            autocomplete="new-password"
            [(ngModel)]="changePassword.newPassword"
            (ngModelChange)="cleanErrorMessage()">
        </wg-hide-show-password>
        <show-errors [control]="newPassword" keySuffix="new_password"></show-errors>
      </div>
      <div class="form-group custom-form-group">
        <label for="confirm-password" [translate]="messageTranslateKeys.confirm_password"></label>
        <wg-hide-show-password>
          <input
            #confirmPassword="ngModel"
            name="confirmPassword"
            type="password"
            validateEqual="newPassword"
            reverse="false"
            class="input-block-level"
            maxlength="50"
            XSSPatternValidator
            required
            placeholder="{{messageTranslateKeys.enter_confirm_password | translate}}"
            autocomplete="new-password"
            [(ngModel)]="changePassword.confirmPassword"
            (ngModelChange)="cleanErrorMessage()">
        </wg-hide-show-password>
        <show-errors [control]="confirmPassword" keySuffix="confirm_password"></show-errors>
      </div>
    </form>
    <wg-http-error-notification *ngIf="errorMessage"></wg-http-error-notification>
  </div>
  <div dialog-footer>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="passwordForm.invalid"
        [translate]="messageTranslateKeys.save"
        (click)="saveNewPassword(passwordForm.valid)">
      </button>
    </div>
  </div>
</wg-dialog>
