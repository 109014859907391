<div class="activate-token">
  <div *ngIf="isValid" >
    <p>{{ message.token_activation_title_description | translate }}</p>

    <h2>{{ message.token_activation_app_download | translate }}</h2>
    <p>{{ message.token_activation_app_download_description | translate }}</p>

    <div class="link-store-container">
      <a href="https://play.google.com/store/apps/details?id=com.watchguard.authpoint" target="_blank">
        <img src="assets/image/google-play-128x128.png" alt="google play">
      </a>
      <a href="https://itunes.apple.com/app/watchguard-authpoint/id1335115425?mt=8" target="_blank">
        <img src="assets/image/apple-store-128x128.png" alt="apple store">
      </a>
    </div>

    <h2>{{ message.token_activation_app_link | translate }}</h2>
    <p>{{ message.token_activation_app_link_description | translate }}</p>

    <a class="btn btn-primary activate-link-container"
      [href]="mobileActivationLink">{{message.token_activation_activate | translate}}
    </a>

    <p>{{ message.token_activation_app_qrcode | translate }}</p>

    <div class="qrcode-box qrcode-image">
      <img [src]="qrCodeImage" alt="qrCode" />
    </div>

    <div class="alert-message-container">
      <div class="alert-icon">
        <wg-icon name="attention"></wg-icon>
      </div>
      <p> {{ message.token_activation_app_qrcode_warning | translate: { time: qrCodeActivationExpirationTime } }} </p>
    </div>
  </div>

  <div class="alert-message-container" *ngIf="!isValid">
    <div class="alert-icon">
      <wg-icon name="attention"></wg-icon>
    </div>
    <h1>{{ message.token_activation_app_qrcode_error | translate }}</h1>
  </div>
</div>