import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { Authentication, Saml } from '../authentication-form/authentication.model';
import { BASE_URL } from '../config/app-settings';
import { BrowserService } from '../shared/browser.service';
import { GeolocationService } from '../shared/geolocation.service';

export const spAuthenticationResolver: ResolveFn<Authentication> = (route: ActivatedRouteSnapshot) => {
  return inject(SPAuthenticationService).resolve(route);
};

@Injectable()
export class SPAuthenticationService {

  constructor(
    private loaderService: LoaderService,
    private http: HttpClient,
    private browserService: BrowserService,
    private geolocationService: GeolocationService
  ) { }

  serviceProvider(authentication: Authentication): Observable<any> {
    this.loaderService.showLoading();
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/serviceProvider`, auth)),
        finalize(() => this.loaderService.hideLoading())
      );
  }

  resolve({ queryParams }: ActivatedRouteSnapshot): Authentication {
    const authenticationString: string = this.browserService.getAuthenticationCookies('authentication');
    if (queryParams.serviceProvider && authenticationString) {
      const authentication: Authentication = JSON.parse(authenticationString);
      authentication.saml = new Saml();
      authentication.saml.selectedSp = queryParams.serviceProvider;
      return authentication;
    }
  }
}
