<div class="login-container">
  <div class="btn-back">
    <wg-back-page *ngIf="showButtonBackPage"></wg-back-page>
  </div>
  <div class="login-panel">
    <div class="login-form">
      <wg-header *ngIf="showHeader"></wg-header>
      <router-outlet></router-outlet>
    </div>
  </div>

  <wg-http-error-notification></wg-http-error-notification>

  <div *ngIf="!!authenticationPolicy" class="second-factory-options">
    <h4 class="second-factory-options-title">
      {{ messageTranslateKeys.signin_options | translate }}
    </h4>
    <ul>
      <li *ngIf="authenticationPolicy.push">
        <a routerLink="/spInitiated/validate" queryParamsHandling="preserve" routerLinkActive="active-link">
          <img [src]="'/assets/installers-theme/push@2x.png'" alt="Push">
          <span>{{ messageTranslateKeys.push | translate }}</span>
        </a>
      </li>
      <li *ngIf="authenticationPolicy.qrCode">
        <a routerLink="/spInitiated/qrcode" queryParamsHandling="preserve" routerLinkActive="active-link">
          <img [src]="'/assets/installers-theme/qr-code@2x.png'" alt="QR Code">
          <span>{{ messageTranslateKeys.qrcode | translate }}</span>
        </a>
      </li>
      <li *ngIf="authenticationPolicy.otp">
        <a routerLink="/spInitiated/otp" queryParamsHandling="preserve" routerLinkActive="active-link">
          <img [src]="'/assets/installers-theme/otp@2x.png'" alt="OTP">
          <span>{{ messageTranslateKeys.one_time_password | translate }}</span>
        </a>
      </li>
      <li>
        <a routerLink="/spInitiated/forgotten-token" queryParamsHandling="preserve" routerLinkActive="active-link">
          <img [src]="'/assets/installers-theme/forgot-token@2x.png'" alt="Forgot token">
          <span>{{ messageTranslateKeys.forgot_token | translate }}</span>
        </a>
      </li>
    </ul>
  </div>

</div>
