import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { sha3_256 } from 'js-sha3';

import { Authentication, AuthenticationPolicy, Saml, SessionIntegration, TransactionData, User } from '../authentication-form/authentication.model';
import { REGION, SESSION_EXPIRATION_TIME_IN_MINUTES } from '../config/app-settings';
import { HeaderService } from '../login/header/header.service';
import { SamlApplicationsResponse, SamlApplication } from '../applications/applications.model';
import { BrowserService, ERROR_CODE, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS, PATH, SamlFormService } from '../shared/';
import { ErrorHandlerService } from '../shared/error-handler.service';
import { ApplicationListService } from '../applications/applications-list/application-list.service';

export class AuthenticationComponent {

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  user: User;
  authentication: Authentication;
  authenticationPolicy: AuthenticationPolicy;
  serviceProviderLogo: string;
  loginInvalid: boolean;
  sessionInvalid: boolean;
  errorMessage: string | Array<string>;
  loginByPassword: boolean;
  selectedSp: SamlApplication;
  serviceProvider: string;

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public errorHandleService: ErrorHandlerService,
    public headerService: HeaderService,
    public browserService: BrowserService,
    public samlFormService: SamlFormService,
    public applicationListService: ApplicationListService
  ) {
  }

  handleSendPasswordError(error: HttpErrorResponse) {
    this.errorHandle(ERROR_PREFIX.forget_password, error);
  }

  loginIdp(response: SamlApplicationsResponse) {
    this.authentication.saml = new Saml();

    this.authentication.user = Object.assign(this.authentication.user || {}, response.user);

    this.browserService.setAuthenticationCookie('accountAlias', this.activatedRoute.snapshot.params.accountAlias, undefined);

    this.applicationListService.setAuthentication(this.authentication);
    this.applicationListService.setSamlApplications(response?.serviceProviderList);
  }

  loginSp(response: any) {
    this.samlFormService.samlSubmitForm(response);
  }

  finishLogin(response: any) {
    this.browserService.clearLocalStorage(this.serviceProvider);
    delete this.authentication.user.authentication;
    delete this.authentication.saml;

    const expiresDate = new Date();
    expiresDate.setMinutes(expiresDate.getMinutes() + SESSION_EXPIRATION_TIME_IN_MINUTES - 1);

    if (response.securityContext) {
      this.authentication.securityContext = response.securityContext;

      const sessionIntegration: SessionIntegration = {
        region: REGION,
        pairingId: sha3_256(response.securityContext.id)
      };
      this.browserService.setSessionIntegrationCookie(sessionIntegration);
    }

    this.browserService.setAuthenticationCookie('isAuthenticationValid', 'true', this.selectedSp, expiresDate);
    this.browserService.setAuthenticationCookie('authentication', JSON.stringify(this.authentication), this.selectedSp);
  }

  setAuthenticationUser(push: boolean, password: string, otp: number, qrCode: boolean) {
    this.authentication.user.authentication = {
      push,
      qrCode,
      oneTimePassword: otp ? otp : undefined,
      password: password ? password : undefined
    };
  }

  getAuthenticationObj(transactionData: TransactionData): any {
    return Object.assign({}, this.authentication, { transactionData });
  }

  cleanErrorMessage() {
    if (this.loginInvalid) {
      this.loginInvalid = false;
    }
    if (this.sessionInvalid) {
      this.sessionInvalid = false;
    }
  }

  errorHandle(requestType: string, error: HttpErrorResponse) {
    this.errorMessage = this.errorHandleService.getMessageError(requestType, error);
    if (this.errorHandleService.verifiyErrorCode([ERROR_CODE.INVALID_ACCOUNT], error)) {
      this.sessionInvalid = true;
    } else {
      this.loginInvalid = true;
    }
  }

  handleLoginSuccess(response: any) {
    this.selectedSp = this.browserService.getItemLocalStorage(`${this.serviceProvider}_serviceProvider`);
    if (response.assertionConsumerServiceUrl) {
      this.loginSp(response);
      this.finishLogin(response);
    } else if (response.serviceProviderList) {
      this.loginIdp(response);
      this.finishLogin(response);
      this.router.navigate(['..', PATH.SERVICE_PROVIDER_LIST], { relativeTo: this.activatedRoute });
    }
  }
}
