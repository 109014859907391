import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

// Polyfill for ResizeObserver
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill;
}

platformBrowserDynamic().bootstrapModule(AppModule);
