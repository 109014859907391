import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { sha1 } from 'js-sha1';

import { IDP_LOGIN } from '../../authentication-form/authentication.model';
import { Branding } from '../../branding/branding.model';
import { BrandingService } from '../../branding/branding.service';
import { HeaderService } from '../../login/header/header.service';
import { AutoUnsubscribe, BrowserService, ERROR_PREFIX, MESSAGE_SUCCESS, MESSAGE_TRANSLATE_KEYS, PATH } from '../../shared/';
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { PasswordService } from '../password-service';
import { SetPassword } from '../password.model';

@Component({
  selector: 'wg-login-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
@AutoUnsubscribe()
export class SetPasswordComponent implements OnInit, OnDestroy {

  setPassword: SetPassword = new SetPassword();
  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  errorMessage: string | Array<string>;
  loginInvalid: boolean;
  compromisedPasswordError = {
    incorrect: 'ui_common_form_error_compromised_password'
  };
  validatePasswordSubscription: Subscription;
  setPasswordSubscription: Subscription;

  constructor(
    private errorHandle: ErrorHandlerService,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private browserService: BrowserService,
    private brandingService: BrandingService,
    public router: Router,
    public setPasswordService: PasswordService
  ) { }

  ngOnInit() {
    this.setUserInformation();
    this.headerService.headerSubmit({
      title: this.messageTranslateKeys.new_password_title
    });
    this.brandingService.branding.subscribe((branding: Branding) => {
      this.browserService.saveServiceProviderLogo(IDP_LOGIN, branding.smallImageUrl);
    });
  }

  ngOnDestroy() {/* It should be here to use the AutoUnsubscribe decorator */ }

  validatePassword(formPassword: NgForm) {
    if (formPassword.valid) {
        this.savePassword();
      } else {
      Object.keys(formPassword.controls).forEach((field: string) => {
        const control = formPassword.control.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  };

 savePassword() {
  const entireHash = sha1(this.setPassword.password);
  this.setPassword.initialHash = entireHash.substring(0,5);
  this.setPassword.finalHash = entireHash.substring(5);
  this.setPasswordSubscription = this.setPasswordService
    .setPassword(this.setPassword)
    .subscribe({
      next: () => {
        this.browserService.setSuccessMessage(MESSAGE_SUCCESS.password_defined);
        this.router.navigate([PATH.SUCESSFULLY_MESSAGE]);
      },
      error: (error: HttpErrorResponse) => {
        this.errorMessage = this.errorHandle.getMessageError(ERROR_PREFIX.set_password, error);
        this.loginInvalid = true;
      }
    });
 }

  private setUserInformation() {
    this.activatedRoute.queryParams
      .subscribe(({ data, userId, accountId }: Params) => {
        if (data && userId && accountId) {
          this.setPassword.emailKey = data;
          this.setPassword.userId = userId;
          this.setPassword.accountId = accountId;
        } else {
          this.router.navigate([PATH.ERROR]);
        }
      });
  }
}
