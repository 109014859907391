import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { AuthenticationPolicy, OTPCode } from '../../authentication-form/authentication.model';
import { HeaderService } from '../../login/header/header.service';
import { AutoUnsubscribe, BrowserService, ERROR_PREFIX, ErrorHandlerService, SamlFormService } from '../../shared/';
import { ApplicationListService } from '../../applications/applications-list/application-list.service';
import { AuthenticationPolicyService } from '../authentication-policy.service';
import { AuthenticationComponent } from '../authentication.component';
import { OTPService } from './otp.service';

@Component({
  selector: 'wg-login-otp',
  templateUrl: './otp.component.html'
})
@AutoUnsubscribe()
export class OtpComponent extends AuthenticationComponent implements OnInit, OnDestroy {

  forgetPassword: boolean;
  otpCode: OTPCode;
  loginByPassword: boolean;

  constructor(
    public activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    public errorHandleService: ErrorHandlerService,
    public router: Router,
    public samlFormService: SamlFormService,
    public browserService: BrowserService,
    public otpService: OTPService,
    public headerService: HeaderService,
    public applicationListService: ApplicationListService,
    private authenticationPolicyService: AuthenticationPolicyService
  ) {
    super(activatedRoute, router, errorHandleService, headerService, browserService, samlFormService, applicationListService);
  }

  ngOnInit() {
    this.otpCode = new OTPCode;
    this.serviceProvider = this.otpService.getServiceProvider();

    this.otpCode.password = this.otpService.getPassword();
    this.authentication = this.otpService.getAuthentication();
    this.serviceProviderLogo = this.otpService.getServiceProviderLogo();
    this.authenticationPolicy = this.otpService.getAuthenticationPolicy();
    this.loginByPassword = this.authenticationPolicy.password && !this.otpCode.password;
    this.headerService.headerSubmit({
      title: this.messageTranslateKeys.authentication_by_otp,
      serviceProviderLogo: this.serviceProviderLogo,
      subtitle: this.authentication.user.username,
    });
  }

  ngOnDestroy() {/* It should be here to use the AutoUnsubscribe decorator */ }

  onSubmit(isValid: boolean) {
    if (isValid) {
      this.loaderService.showLoading();
      this.setAuthenticationUser(false, this.otpCode.password, this.otpCode.oneTimePassword, false);
      this.otpService
        .login(this.authentication, this.authenticationPolicy.isUpgrade).pipe(
          finalize(() => this.loaderService.hideLoading())
        )
        .subscribe({
          next: (data: any) => this.handleLoginSuccess(data),
          error: (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_otp, error),
        });
    }
  }

  // TODO: Validate if it is really necessary
  updateAuthenticationPolicy() {
    this.authenticationPolicyService
      .fetchAuthenticationPolicy(this.authentication, this.serviceProvider)
      .subscribe({
        next: (authenticationPolicy: AuthenticationPolicy) => {
          this.authenticationPolicy = authenticationPolicy;
          this.otpService.setAuthenticationPolicy(authenticationPolicy);
        },
        error: (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_policy, error)
      });
  }
}
