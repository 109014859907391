<div>
  <div *ngIf="errorMessage" class="error-message">
    <wg-icon name="attention"></wg-icon>
    <span class="align-middle">{{ errorMessage | translate }}</span>
  </div>

  <wg-ssp-hardware-token-form
    [exhibitionType]="ExhibitionType.PAGE"
    [(serialNumber)]="serialNumber"
    [(otp)]="otp"
    (serialNumberChange)="cleanErrorMessage()"
    (otpChange)="cleanErrorMessage()">
  </wg-ssp-hardware-token-form>

  <button class="btn btn-primary" type="button" [disabled]="shouldFooterButtonBeDisabled()"
    (click)="activateHardwareToken()">
    {{ messageTranslateKeys.finish | translate }}
  </button>
</div>
