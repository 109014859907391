<div class="applications">
  <div class="saml-header-main">
    <header>
      <div class="logo">
        <img [src]="headerImageUrl" alt="Logo" (error)="loadDefaultHeaderImage()"/>
      </div>

      <div class="nav-options">
        <div class="search">
          <wg-search
            [title]="messageTranslateKeys.search | translate"
            [placeholder]="messageTranslateKeys.search | translate"
            [hasClearSearchOption]="true"
            [(searchTerm)]="searchValue"
            (searchTermChange)="searchTermChange($event)">
          </wg-search>
        </div>

        <div class="menu-options">
          <a class="user-icon" (click)="toggleChangePasswordMenu($event)">
            <wg-icon name="user-circle-line"></wg-icon>
            <div
              class="menu change-password-menu"
              [ngClass]="{ show: hasToShowChangePasswordMenu }"
              (clickOutside)="clickOutside($event)">
              <div class="account-name d-flex flex-column align-items-center">
                <p class="fullName">{{ fullName }}</p>
                <p class="username">{{ username }}</p>
                <p class="email">{{ email }}</p>
              </div>
              <nav class="nav-menu">
                <ul>
                  <li>
                    <a
                      class="change-password"
                      [translate]="messageTranslateKeys.change_password"
                      (click)="openChangePasswordDialog($event)">
                    </a>
                </ul>
              </nav>
            </div>
          </a>
          <a class="menu-icon" (click)="toggleUserMenu($event)">
            <wg-icon name="more-menu"></wg-icon>
            <div
              class="menu user-menu"
              [ngClass]="{ show: hasToShowUserMenu }"
              (clickOutside)="clickOutside($event)">
              <nav class="nav-menu">
                <ul>
                  <li *ngIf="isSspEnabled">
                    <a
                      [translate]="messageTranslateKeys.activate_mobile_token"
                      (click)="openMobileTokenActivationDialog($event)">
                    </a>
                  </li>
                  <li *ngIf="isSspEnabled">
                    <a
                      [translate]="messageTranslateKeys.activate_hardware_token"
                      (click)="openHardwareTokenActivationDialog($event)">
                    </a>
                  </li>
                  <li>
                    <a [translate]="messageTranslateKeys.logout" (click)="logout()"></a>
                  </li>
                </ul>
              </nav>
            </div>
          </a>
        </div>
      </div>
    </header>

  </div>

  <div class="applications-grid" [ngClass]="{'no-grid': applications?.length }">
    <ng-container *ngIf="applications?.length; else noApplicationsTemplate">

      <div class="application-cards">
        <a *ngFor="let application of applications" class="application-card" (click)="openApplication(application.url)">
          <div class="custom-card">
            <div class="image">
              <img src="{{ application.getImage(accountId, headerImageUrl) }}" alt="" (error)="onApplicationImageError($event)"/>
            </div>
            <div class="caption">
              <p [title]="application.name" class="text-truncate">{{ application.name }}</p>
              <wg-icon name="caret-right"></wg-icon>
            </div>
          </div>
        </a>
      </div>
    </ng-container>

    <ng-template #noApplicationsTemplate>
      <div class="no-applications">
        <h2
          class="text-center"
          [translate]="
            searchValue ?
            this.messageTranslateKeys.without_resources_search :
            this.messageTranslateKeys.without_resources">
        </h2>
      </div>
    </ng-template>
  </div>
</div>

<wg-edit-password
  [editPasswordDialog]="isEditPasswordDialogOpen"
  [accountId]="authentication.accountId"
  [username]="authentication.user.username"
  (closeEditDialog)="closeEditPasswordDialog()">
</wg-edit-password>

<wg-ssp-mobile-token-activation-dialog
  [mustOpenMobileTokenActivationDialog]="isMobileTokenActivationDialogOpen"
  [accountId]="authentication.accountId"
  [username]="authentication.user.username"
  (closeMobileTokenActivationDialog)="closeMobileTokenActivationDialog()">
</wg-ssp-mobile-token-activation-dialog>

<wg-hardware-token-activation-dialog
  [mustOpenHardwareTokenActivationDialog]="isHardwareTokenActivationDialogOpen"
  [accountId]="authentication.accountId"
  [username]="authentication.user.username"
  (closeHardwareTokenActivationDialog)="closeHardwareTokenActivationDialog()">
</wg-hardware-token-activation-dialog>
