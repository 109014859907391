import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '@watchguard/wg-loader';
import { sha3_256 } from 'js-sha3';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '../authentication-form/authentication.service';
import { BASE_URL_SSP } from '../config/app-settings';
import { BrowserService } from '../shared/browser.service';
import { GeolocationService } from '../shared/geolocation.service';
import {
  AuthenticateUserPasswordRequest,
  AuthenticateUserPasswordResponse,
  SspConfigRequest,
  SspToggleFeatureResponse,
  HardwareTokenActivationRequest,
  HardwareTokenActivationResponse,
  MobileTokenActivationRequest,
  MobileTokenActivationResponse
} from './ssp-token.model';


@Injectable()
export class SspTokenService extends AuthenticationService {

  securityContextId: string;
  verifier: string;

  constructor(
    public router: Router,
    public http: HttpClient,
    public browserService: BrowserService,
    public geolocationService: GeolocationService,
    private loaderService: LoaderService
  ) {
    super(router, http, browserService, geolocationService);
  }

  authenticateUserPassword(accountId: string, body: AuthenticateUserPasswordRequest):
    Observable<AuthenticateUserPasswordResponse> {
    const baseUrl = BASE_URL_SSP.replace(':accountId', accountId);
    this.loaderService.showLoading();
    return this.http
      .post<AuthenticateUserPasswordResponse>(`${baseUrl}/authentication`, body)
      .pipe(finalize(() => this.loaderService.hideLoading()));
  }

  activateHardwareToken(accountId: string, body: HardwareTokenActivationRequest):
    Observable<HardwareTokenActivationResponse> {
    const baseUrl = BASE_URL_SSP.replace(':accountId', accountId);
    this.loaderService.showLoading();
    return this.http
      .post<HardwareTokenActivationResponse>(
        `${baseUrl}/management/hardwaretoken`,
        body
      )
      .pipe(finalize(() => this.loaderService.hideLoading()));
  }

  getQrCodeData(accountId: string, body: MobileTokenActivationRequest):
    Observable<MobileTokenActivationResponse> {
    const baseUrl = BASE_URL_SSP.replace(':accountId', accountId);
    this.loaderService.showLoading();
    return this.http
      .post<MobileTokenActivationResponse>(
        `${baseUrl}/management/mobiletoken`,
        body
      )
      .pipe(finalize(() => this.loaderService.hideLoading()));
  }

  get challenge(): string {
    this.generateVerifier();
    return sha3_256(this.verifier);
  }

  getSspToggleFeature(accountId: string, sspConfigRequest: SspConfigRequest): Observable<SspToggleFeatureResponse> {
    const baseUrl = BASE_URL_SSP.replace(':accountId', accountId);
    this.loaderService.showLoading();
    
    return this.http
      .post<SspToggleFeatureResponse>(`${baseUrl}/config`,sspConfigRequest)
      .pipe(finalize(() => this.loaderService.hideLoading()));
  }

  private generateVerifier() {
    this.verifier = String(Math.random()).slice(2, 8);
  }

}
