<wg-forget-authenticator-alert
  [authenticationPolicy]="authenticationPolicy"
  [buttonLabel]="messageTranslateKeys.forget_token | translate">
</wg-forget-authenticator-alert>
<form id="form-password" #loginForm="ngForm">
    <span *ngIf="loginInvalid" class="error-message">
        <wg-icon name="attention"></wg-icon>
        <span class="align-middle" [translate]="errorMessage"></span>
    </span>
    <div class="form-group custom-form-group required" *ngIf="loginByPassword">
        <label for="qrcode" [translate]="messageTranslateKeys.password"></label>
        <wg-hide-show-password>
            <input
                #password="ngModel"
                type="password"
                id="form-password-input"
                name="loginForm.password"
                class="input-block-level"
                placeholder="{{ messageTranslateKeys.password | translate}}"
                autocomplete="off"
                wgSubmitOnEnter=".login-btn"
                XSSPatternValidator="false"
                autofocus
                required
                [(ngModel)]="loginForm.password"
                (ngModelChange)="cleanErrorMessage()">
        </wg-hide-show-password>
        <show-errors [control]="password" keySuffix="password"></show-errors>
    </div>
    <div *ngFor="let authenticationPolicyLabel of authenticationPolicyLabels; let i = index">
        <button *ngIf="i===0" class="btn btn-primary login-btn" type="button" (click)="onSubmit(loginForm, authenticationPolicyLabel.name)"
            type="button" [disabled]="loginForm.invalid" [translate]="authenticationPolicyLabel.label"></button>
        <a *ngIf="i!==0" class="login-option-link" (click)="onSubmit(loginForm, authenticationPolicyLabel.name)"
            [ngClass]="{ disabled: loginForm.invalid }" [translate]="authenticationPolicyLabel.label"></a>
    </div>
    <div class="form-actions" *ngIf="authenticationPolicyLabels.length === 0">
        <button id="form-password-submit" class="btn btn-primary login-btn" [disabled]="loginForm.invalid" type="button" (click)="onSubmit(loginForm, 'PASSWORD')"
            [translate]="messageTranslateKeys.finish"></button>
    </div>
</form>
<wg-forgot-password
    *ngIf="authenticationPolicy.hasForgotPassword"
    [username]="authentication.user.username"
    [accountId]="authentication.accountId"
    (errorResponse)="handleSendPasswordError($event)">
</wg-forgot-password>
<wg-forget-authenticator
    [authenticationPolicy]="authenticationPolicy"
    [authentication]="authentication"
    (loginResponse)="handleLoginSuccess($event)">
</wg-forget-authenticator>
<wg-pending-token-activation
    [hasPendingToken]="authenticationPolicy.pendingToken"
    [authentication]="authentication"
    (authenticationPolicyChange)="updateAuthenticationPolicy()">
</wg-pending-token-activation>
