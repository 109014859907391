<form
  #hardwareTokenForm="ngForm"
  id="hardwareTokenForm"
  [class.form-for-dialog]="exhibitionType === ExhibitionType.DIALOG">
  <ng-container *ngIf="exhibitionType === ExhibitionType.DIALOG">
    <p class="step-label"><b>{{ messageTranslateKeys.password_authentication_second_step | translate }}</b></p>
    <p>{{ messageTranslateKeys.hardware_token_activation_second_step | translate }}</p>
  </ng-container>

  <div class="form-group custom-form-group required mt-4">
    <label for="serialNumber">{{ messageTranslateKeys.token_serial_number | translate }}</label>
    <div class="infos d-flex flex-row align-items-center">
      <input
        autofocus
        required
        name="serialNumber"
        id="serialNumber"
        class="form-control"
        type="text"
        maxlength="50"
        placeholder="{{ messageTranslateKeys.enter_token_serial_number | translate }}"
        autocomplete="off"
        [(ngModel)]="serialNumber"
        (input)="serialNumberChange.emit(serialNumber)">

      <wg-info-popover
        *ngIf="exhibitionType === ExhibitionType.DIALOG"
        [text]="popoverContent">
      </wg-info-popover>

      <ng-template #popoverContent>
        <div>
          <div class="d-flex flex-column align-items-center">
            <p>{{ messageTranslateKeys.hardware_token_activation_serial_number_info_popover | translate }}</p>
            <img src="/assets/image/token.png">
          </div>
        </div>
      </ng-template>
    </div>
    <show-errors
      fieldName="{{ messageTranslateKeys.token_serial_number | translate }}"
      [control]="hardwareTokenForm.controls.serialNumber">
    </show-errors>
  </div>

  <div class="form-group custom-form-group required mt-1">
    <label for="otp">{{ messageTranslateKeys.otp | translate }}</label>
    <div class="infos d-flex flex-row align-items-center">
      <input
        required
        OnlyNumber
        rangeValidator
        name="otp"
        id="otp"
        class="form-control"
        type="text"
        minlength="6"
        maxlength="6"
        placeholder="{{ messageTranslateKeys.enter_otp | translate }}"
        autocomplete="off"
        [(ngModel)]="otp"
        (input)="otpChange.emit(otp)">

      <wg-info-popover
        *ngIf="exhibitionType === ExhibitionType.DIALOG"
        text="{{ messageTranslateKeys.hardware_token_activation_otp_info_popover | translate }}">
      </wg-info-popover>
    </div>
    <show-errors
      [control]="hardwareTokenForm.controls.otp"
      keySuffix="otp">
    </show-errors>
  </div>
</form>
