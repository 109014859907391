import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { PendingTokenActivationRequest, PendingTokenActivationResponse } from '../../models/pending-token-activation';
import { AutoUnsubscribe, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS, NavigatorInformationService } from '../../shared';
import { ErrorHandlerService } from '../../shared/error-handler.service';
import { Authentication } from '../authentication.model';
import { PendingTokenActivationService } from './pending-token-activation.service';
import { SspTokenService } from '../../self-service-portal/ssp-token.service';
import { SspToggleFeatureResponse } from '../../self-service-portal/ssp-token.model';


@Component({
  selector: 'wg-pending-token-activation',
  templateUrl: './pending-token-activation.component.html',
  styleUrls: ['./pending-token-activation.component.scss'],
  providers: [PendingTokenActivationService]
})
@AutoUnsubscribe()
export class PendingTokenActivationComponent implements OnInit {
  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  isPendingTokenActivationDialogOpen = false;
  showPendingTokenActivation = false;
  isMobileDevice = true;
  isSspEnabled = true;
  errorMessage: string = null;
  password: string = null;
  generateQrCodeSubscripion: Subscription;
  validateSspToggleSubscripion: Subscription;
  isNonMFA = false;
  qrCodeImage: SafeResourceUrl;
  mobileActivationLink: SafeResourceUrl;

  @Input() authentication: Authentication;

  @Input() set hasPendingToken(hasPendingToken: boolean) {
    this.showPendingTokenActivation = this.isActivatePendingTokenAvailable(hasPendingToken);
  }

  @Output() authenticationPolicyChange = new EventEmitter<boolean>();

  constructor(
    private sanitizer: DomSanitizer,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private pendingTokenActivationService: PendingTokenActivationService,
    private sspTokenService: SspTokenService,
    private navigatorInformationService: NavigatorInformationService
  ) { }

  ngOnInit(): void {
    const device = this.navigatorInformationService.getDevice();
    this.isMobileDevice = device === "mobile" || device === "tablet";

    this.validateSspToggleSubscripion = this.sspTokenService.getSspToggleFeature(this.authentication.accountId)
      .subscribe((sspToggleFeatureResponse: SspToggleFeatureResponse) => {
        this.isSspEnabled = sspToggleFeatureResponse.preference;
      });
  }

  openPendingTokenActivationDialog() {
    this.isPendingTokenActivationDialogOpen = true;
  }

  closePendingTokenActivationDialog() {
    if (this.qrCodeImage) {
      this.authenticationPolicyChange.emit(true);
    }

    this.isPendingTokenActivationDialogOpen = false;
    this.qrCodeImage = null;
    this.errorMessage = null;
    this.password = null;
    if (this.generateQrCodeSubscripion) {
      this.generateQrCodeSubscripion.unsubscribe();
    }
  }

  generateActivationQrCode() {
    if (!this.qrCodeImage) {
      this.loaderService.showLoading();

      this.generateQrCodeSubscripion = this.pendingTokenActivationService
        .generateQrCodeActivationData(this.createPendingTokenActivationRequest())
        .pipe(
          finalize(() => {
            this.loaderService.hideLoading();
            setTimeout(() => window.dispatchEvent(new Event('resize')));
          })
        )
        .subscribe({
          next: (response: PendingTokenActivationResponse) => {
            this.isNonMFA = response.qrCodeImage == undefined;
            this.qrCodeImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + response.qrCodeImage);
            this.mobileActivationLink = this.sanitizer.bypassSecurityTrustResourceUrl(response.mobileActivationLink);
            this.errorMessage = null;
          },
          error: (error: HttpErrorResponse) => this.errorHandler(ERROR_PREFIX.pending_token, error)
        });
    } else {
      this.closePendingTokenActivationDialog();
    }
  }

  private createPendingTokenActivationRequest(): PendingTokenActivationRequest {
    return {
      accountId: this.authentication.accountId,
      user: {
        username: this.authentication.user.username,
        authentication: {
          password: this.password
        }
      }
    };
  }

  private errorHandler(requestType: string, httpErrorResponse: HttpErrorResponse) {
    this.errorMessage = this.errorHandlerService.getMessageError(requestType, httpErrorResponse);
  }

  private isActivatePendingTokenAvailable(hasPendingToken: boolean) {
    return hasPendingToken && !this.isRdWeb();
  }

  private isRdWeb(): boolean {
    return this.activatedRoute.snapshot.queryParams.theme === 'agent';
  }
}
