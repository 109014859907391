import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event as RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

import { Authentication, AuthenticationPolicy } from '../authentication-form/authentication.model';
import { BrowserService, MESSAGE_TRANSLATE_KEYS } from '../shared';

@Component({
  selector: 'wg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  showForgotAuthenticationOption: boolean;
  showButtonBackPage: boolean;
  serviceProvider: string;
  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  showHeader = true;

  authenticationPolicy: AuthenticationPolicy;

  constructor(
    private router: Router,
    private browserService: BrowserService,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isShowButtonBack(event.url);
      this.showHeader = !(/^\/[a-z0-9_-]+\/resources\/?$/.test(event.url));
    });
  }

  isShowButtonBack(path: string) {
    this.serviceProvider = this.activatedRoute.snapshot.queryParams.serviceProvider;
    if (this.serviceProvider && this.hasAuthenticationPolicy() && this.userIsLogged()) {
      this.showButtonBackPage = this.validate(path);
    } else {
      this.showButtonBackPage = this.shouldShowBackButonBasedOnPath(path);
    }
  }

  private shouldShowBackButonBasedOnPath(path: string): boolean {
    return /validate|token|otp|qrcode|authenticator-forgotten|send_email_forget_password/.test(path);
  }

  private validate(path: string): boolean {
    const { push, qrCode }: AuthenticationPolicy = this.authenticationPolicy;
    if (/otp|token|qrcode|authenticator-forgotten|send_email_forget_password/.test(path)) {
      if (/otp/.test(path)) {
        return (push || qrCode);
      } else {
        return true;
      }
    }
  }

  private hasAuthenticationPolicy(): boolean {
    this.authenticationPolicy = this.browserService.getItemLocalStorage(`${this.serviceProvider}_authenticationPolicy`);
    return !!this.authenticationPolicy;
  }

  private userIsLogged(): boolean {
    const authentication: Authentication = this.browserService.getItemLocalStorage(`${this.serviceProvider}_authentication`);
    return !!(authentication && authentication.securityContext);
  }

}
