<form #forgotAuthenticator="ngForm">
  <div *ngIf="!isForgetTokenInformation">
    <div class="random-challenge">
      <label>{{ messageTranslateKeys.forgot_token_activation_code | translate }}</label><br><b>{{ randomChallenge }}</b>
    </div>
    <div class="form-group">
      <label>{{ messageTranslateKeys.password | translate }}</label>
      <wg-hide-show-password>
        <input type="password" autofocus class="form-control" name="password" required #password="ngModel" name="password"
          [(ngModel)]="forgotTokenRequest.password" placeholder="{{ messageTranslateKeys.password | translate}}"
          autocomplete="off">
      </wg-hide-show-password>
      <show-errors [control]="password" keySuffix="password"></show-errors>
    </div>
    <div class="form-group">
      <label>{{ messageTranslateKeys.forgot_token_hour_title | translate }}</label>
      <input type="number" pattern="[0-9]*" rangeValidator rangeMin="1" rangeMax="72" class="form-control" OnlyNumber
        #period="ngModel" [(ngModel)]="forgotTokenRequest.period" name="period" required
        placeholder="{{ messageTranslateKeys.forgot_token_hour_ph | translate}}" autocomplete="off">
      <show-errors [control]="period" [customMessage]="{ pattern: 'ui_common_form_error_pattern_only_number_period' }" keySuffix="period"></show-errors>
    </div>
    <div class="form-group">
      <label>{{ messageTranslateKeys.forgot_token_response_title | translate }}</label>
      <input type="text" class="form-control forgot-authenticator-response" pattern="[0-9]*"
        wgSubmitOnEnter=".forget-authenticator-btn" minlength="6" maxlength="6" OnlyNumber #authenticationCode="ngModel"
        [(ngModel)]="forgotTokenRequest.authenticationCode" name="authenticationCode" required
        placeholder="{{ messageTranslateKeys.forgot_token_response_ph | translate }}" autocomplete="off">
      <show-errors [control]="authenticationCode" [customMessage]="{ pattern: 'ui_common_form_error_pattern_only_number_verification_code' }" keySuffix="verification_code"></show-errors>
    </div>
    <div class="forget-authenticator-error-message text-danger" *ngIf="errorMessage" [@fadeAnimation]="'in'">
      {{ errorMessage | translate }}
    </div>
  </div>
  <div *ngIf="isForgetTokenInformation">
    <label class="information">
      <p> {{ messageTranslateKeys.forgot_token_information_description | translate }} </p>
      <ol>
        <li>
          <p [innerHTML]=" messageTranslateKeys.forgot_token_information_step_one | translate "> </p>
        </li>
        <li>
          <p [innerHTML]=" messageTranslateKeys.forgot_token_information_step_two | translate "></p>
        </li>
        <li>
          <p [innerHTML]=" messageTranslateKeys.forgot_token_information_step_three | translate "></p>
        </li>
        <li>
          <p [innerHTML]=" messageTranslateKeys.forgot_token_information_step_four | translate "></p>
        </li>
      </ol>
      <p> {{ messageTranslateKeys.forgot_token_information_finish | translate }} </p>
    </label>
  </div>

  <div class="forget-authenticator-footer row ui-dialog-buttonpane no-gutters">
    <div class="col-6 text-left">
      <a *ngIf="!isForgetTokenInformation" (click)="isForgetTokenInformationToggle()">
        <label class="how-to-use"> {{ messageTranslateKeys.forgot_token_information_help | translate }} </label>
      </a>
    </div>
    <div class="col-6 text-right">
      <button *ngIf="!isForgetTokenInformation" (click)="validateAuthenticationCode()" class="btn btn-primary login-btn forget-authenticator-btn"
        [disabled]="forgotAuthenticator.invalid" type="button"
        [translate]="messageTranslateKeys.forgot_token_validate"></button>
      <button *ngIf="isForgetTokenInformation" (click)="isForgetTokenInformationToggle()"
        class="btn btn-primary login-btn forget-authenticator-btn" [disabled]="forgotAuthenticator.invalid" type="button"
        [translate]="messageTranslateKeys.back"></button>
    </div>
  </div>
</form>
