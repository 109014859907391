<wg-forget-authenticator-alert
  [authenticationPolicy]="authenticationPolicy"
  [buttonLabel]="messageTranslateKeys.finish | translate">
</wg-forget-authenticator-alert>

<form #loginForm="ngForm">
  <div *ngIf="loginInvalid" class="error-message">
    <wg-icon name="attention"></wg-icon>
    <span class="align-middle" [translate]="errorMessage"></span>
  </div>

  <div class="form-group custom-form-group required">
    <label for="qrcode" [translate]="messageTranslateKeys.password"></label>
    <wg-hide-show-password>
      <input
        type="password"
        name="password"
        class="input-block-level"
        placeholder="{{ messageTranslateKeys.password | translate}}"
        wgSubmitOnEnter=".authenticator-forgotten-btn"
        autocomplete="off"
        autofocus
        required
        [(ngModel)]="password"
        (ngModelChange)="cleanErrorMessage()">
      </wg-hide-show-password>
      <show-errors [control]="loginForm.controls.password" keySuffix="password"></show-errors>
  </div>

  <div class="form-actions">
    <button
      class="btn btn-primary login-btn authenticator-forgotten-btn"
      type="button"
      [disabled]="loginForm.invalid"
      [translate]="messageTranslateKeys.finish"
      (click)="onSubmit(loginForm.valid)">
    </button>
  </div>
</form>

<wg-forgot-password
    *ngIf="authenticationPolicy.hasForgotPassword"
    [username]="authentication.user.username"
    [accountId]="authentication.accountId"
    (errorResponse)="handleSendPasswordError($event)">
</wg-forgot-password>
<wg-forget-authenticator
    [authenticationPolicy]="authenticationPolicy"
    [authentication]="authentication"
    [allowExtendTime]="true"
    (loginResponse)="handleLoginSuccess($event)">
</wg-forget-authenticator>
<wg-pending-token-activation
    [authentication]="authentication"
    (authenticationPolicyChange)="updateAuthenticationPolicy()">
</wg-pending-token-activation>
