import { Component } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'wg-internet-monitoring',
  templateUrl: './internet-monitoring.component.html',
  styleUrls: ['./internet-monitoring.component.scss']
})
export class InternetMonitoringComponent {

  isOffline = merge(
    of(navigator.onLine),
    fromEvent(window, 'online').pipe(map(() => true)),
    fromEvent(window, 'offline').pipe(map(() => false))
  ).pipe(
    map(isOnline => !isOnline),
    debounceTime(500),
    shareReplay(1)
  );

}
