import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BASE_URL } from '../config/app-settings';
import { GeolocationService } from '../shared/geolocation.service';
import { BrowserService, PATH } from './../shared/';
import { Authentication, AuthenticationPolicy, IDP_LOGIN } from './authentication.model';

export class AuthenticationService {

  private serviceProvider: string;
  private serviceProviderLogo: string;
  private authentication: Authentication;
  private authenticationPolicy: AuthenticationPolicy;
  private authenticationPolicyLabels: Array<any>;

  constructor(
    public router: Router,
    public http: HttpClient,
    public browserService: BrowserService,
    public geolocationService: GeolocationService
  ) { }

  getAuthenticationPolicy(): AuthenticationPolicy {
    return this.authenticationPolicy;
  }

  setAuthenticationPolicy(authenticationPolicy: AuthenticationPolicy) {
    this.authenticationPolicy = authenticationPolicy;
  }

  setAuthenticationPolicyLabels(authenticationPolicyLabels: Array<any>) {
    this.authenticationPolicyLabels = authenticationPolicyLabels;
  }

  getAuthenticationPolicyLabels(): Array<any> {
    return this.authenticationPolicyLabels;
  }

  getAuthentication(): Authentication {
    return this.authentication;
  }

  setAuthentication(authentication: Authentication) {
    this.authentication = authentication;
  }

  getServiceProvider(): string {
    return this.serviceProvider;
  }

  setServiceProvider(serviceProvider: string) {
    this.serviceProvider = serviceProvider;
  }

  getServiceProviderLogo() {
    return this.serviceProviderLogo;
  }

  setServiceProviderLogo(serviceProviderLogo: object) {
    if (serviceProviderLogo) {
      this.serviceProviderLogo = serviceProviderLogo['serviceProviderLogo'];
    }
  }

  login(authentication: Authentication, isUpgrade?: boolean): Observable<any> {
    let observable: Observable<any>;
    if (authentication.saml && authentication.saml.selectedSp) {
      observable = isUpgrade ? this.authenticateUpgrade(authentication) : this.authenticateLoginMFAByPass(authentication);
    } else {
      observable = this.authenticate(authentication);
    }
    return observable;
  }

  resolve({ queryParams, url }: ActivatedRouteSnapshot) {
    const serviceProvider: string = queryParams.serviceProvider ? queryParams.serviceProvider : IDP_LOGIN;

    const authentication: Authentication = this.browserService.getItemLocalStorage(`${serviceProvider}_authentication`);
    const authenticationPolicy: AuthenticationPolicy = this.browserService.getItemLocalStorage(`${serviceProvider}_authenticationPolicy`);

    if (authentication && this.isValidRoute(url[0].path, authenticationPolicy)) {
      this.setServiceProvider(serviceProvider);
      this.setAuthentication(authentication);
      this.setAuthenticationPolicy(authenticationPolicy);
      this.setServiceProviderLogo(this.browserService.getItemLocalStorage(`${serviceProvider}_serviceProviderLogo`));
      this.setAuthenticationPolicyLabels(this.browserService.getItemLocalStorage(`${serviceProvider}_authenticationPolicyLabels`));
    } else {
      this.router.navigate([PATH.ERROR]);
    }
  }

  private isValidRoute(path: string, authenticationPolicy: AuthenticationPolicy): boolean {
    switch (path) {
      case PATH.VALIDATE:
        return !!authenticationPolicy;
      case PATH.QR_CODE:
        return authenticationPolicy.qrCode;
      case PATH.OTP:
        return authenticationPolicy.otp;
      case PATH.FORGOTTEN:
        return authenticationPolicy.isAuthenticatorForgotten;
      case PATH.REQUEST_FORGOTTEN:
        return true;
      default:
        return false;
    }
  }

  private authenticate(authentication: Authentication) {
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/authentication`, auth))
      );
  }

  private authenticateLoginMFAByPass(authentication: Authentication): Observable<any> {
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/serviceProvider/authentication`, auth))
      );
  }

  private authenticateUpgrade(authentication: Authentication): Observable<any> {
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/serviceProvider/authenticationUpgrade`, auth))
      );
  }
}

