import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { HeaderService } from '../login/header/header.service';
import { MESSAGE_TRANSLATE_KEYS } from '../shared/ui-strings';
import { qrCodeActivationExpirationDefaultTime } from '../shared/constants';

@Component({
  selector: 'wg-aaas-activation',
  templateUrl: './token-activation.component.html',
  styleUrls: ['./token-activation.component.scss']
})
export class ActivationComponent implements OnInit {

  message = MESSAGE_TRANSLATE_KEYS;
  qrCodeImage: SafeResourceUrl;
  mobileActivationLink: SafeResourceUrl;
  isValid: boolean;
  qrCodeActivationExpirationTime: number;

  constructor(
    private headerService: HeaderService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.headerService.headerSubmit({
      title: this.message.token_activation_title,
    });

    const activation = this.activatedRoute.snapshot.data.activation;
    if (activation && !activation.error) {
      this.qrCodeImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + activation.qrCodeImage);
      this.mobileActivationLink = this.sanitizer.bypassSecurityTrustResourceUrl(activation.mobileActivationLink);
      this.qrCodeActivationExpirationTime = activation.qrCodeActivationExpirationTime ?? qrCodeActivationExpirationDefaultTime;
      this.isValid = true;
    }
  }
}
