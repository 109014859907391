import { Injectable, inject} from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AccountInfo } from '../account-info/account-info.model';
import { Authentication, IDP_LOGIN } from '../authentication-form/authentication.model';
import { BrowserService } from '../shared';

import { BrandingService } from './branding.service';

export const brandingResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  return inject(BrandingResolver).resolve(route);
};

@Injectable()
export class BrandingResolver {
  constructor(
    private brandingService: BrandingService,
    private browserService: BrowserService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { accountId, serviceProvider, theme, spRequestId } = route.queryParams;
    const accountInfo: AccountInfo = route.parent.data.accountInfo;

    const isServiceProvider = (serviceProvider || spRequestId) && !theme;
    const isRdWeb = (serviceProvider || spRequestId) && theme === 'agent';
    const isIdp = serviceProvider === IDP_LOGIN;

    if (isServiceProvider && !isIdp) {
      return of(true);
    }

    if (accountId || accountInfo) {
      this.brandingService.loadBranding(accountId || accountInfo && accountInfo.accountId);
    } else if (isRdWeb) {
      const authentication: Authentication = this.browserService.getItemLocalStorage(`${serviceProvider}_authentication`);
      this.brandingService.loadBranding(authentication && authentication.accountId, !!authentication);
    } else {
      this.brandingService.loadBranding(null);
    }

    return of(true);
  }
}
