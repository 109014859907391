<form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
  <span *ngIf="loginInvalid" class="error-message">
    <wg-icon name="attention"></wg-icon>
    <span class="align-middle" [translate]="errorMessage"></span>
  </span>
  <div class="form-group custom-form-group required">
    <label for="password" [translate]="messageTranslateKeys.password"></label>
    <wg-hide-show-password>
      <input id="password" type="password" #passwordInput="ngModel" autofocus required autocomplete="off"
        class="input-block-level" name="password" [(ngModel)]="password"
        (ngModelChange)="cleanErrorMessage()" XSSPatternValidator="false"
        placeholder="{{ messageTranslateKeys.password | translate}}">
    </wg-hide-show-password>
    <show-errors [control]="passwordInput" keySuffix="password"></show-errors>
  </div>
  <div class="form-group for-checkbox mx-auto custom-form-group">
    <div class="form-check text-left ml-2">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" required name="authMethod" value="activateMobileToken" [(ngModel)]="chosenAuthMethod">
        {{ 'auth_ssp_activate_mobile_token' | translate}}
      </label>
    </div>
    <div class="form-check text-left ml-2">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" required name="authMethod" value="activateHardwareToken" [(ngModel)]="chosenAuthMethod">
        {{ 'auth_ssp_activate_hardware_token' | translate}}
      </label>
    </div>
    <div class="form-check text-left ml-2" *ngIf="isOnlyPassword">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" required name="authMethod" value="login"  [(ngModel)]="chosenAuthMethod">
        {{ 'auth_ssp_login_to_the_portal' | translate}}
      </label>
    </div>
  </div>
  <div class="form-actions" >
    <button class="btn btn-primary login-btn" [disabled]="loginForm.invalid" type="submit">
      {{( chosenAuthMethod === 'login' ? messageTranslateKeys.finish : messageTranslateKeys.next )  | translate}}
    </button>
  </div>
</form>
