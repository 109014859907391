import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BACKEND_CUSTOM_ERRORS } from './ui-strings';
import { ApiError } from '../models';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        return next
            .handle(request).pipe(
                catchError(this.catchError)
            );
    }

    private hasCustomError({ code, message }: ApiError): boolean {
        return !!code && !!message && !!BACKEND_CUSTOM_ERRORS.find(e => e === code);
    }

    private getCustomError({ code }: ApiError): string {
        return 'auth_sso_api_error_' + code;
    }

    private catchError = (resp: HttpErrorResponse) => {
        const newResponse = { ...resp };
        if (typeof newResponse.error === 'string') {
            newResponse.error = JSON.parse(newResponse.error);
        }
        if (newResponse.error && newResponse.error.errors) { // In case errors may be null or undefined
            const [errorMessage] = newResponse.error.errors
                .filter(this.hasCustomError)
                .map(this.getCustomError)
                .slice(0, 1);
            // Using only the first error on the list to determine which message should be displayed
            // If errors property receives a null or undefined, in the end,
            // it will show a message based just in the HTTP status code
            newResponse.error.mainMessageKey = errorMessage;
        }
        return throwError(() => newResponse);
    }
}
