import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AutoUnsubscribe, MESSAGE_TRANSLATE_KEYS } from '../../shared';
import { ExhibitionType } from '../ssp-token.model';

@Component({
  selector: 'wg-ssp-hardware-token-form',
  templateUrl: './hardware-token-form.component.html',
  styleUrls: ['hardware-token-form.component.scss']
})
@AutoUnsubscribe()
export class HardwareTokenFormComponent {

  @Input() serialNumber: string;
  @Input() otp: number;
  @Input() exhibitionType: ExhibitionType;
  @Output() serialNumberChange = new EventEmitter<string>();
  @Output() otpChange = new EventEmitter<number>();

  ExhibitionType = ExhibitionType;

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

}
