<div *ngIf="isShowLinkForgetAuthentication() || allowExtendTime" class="login-panel forget-token">
  <a (click)="openForgetAuthenticatorDialog()"
    [translate]="allowExtendTime ? messageTranslateKeys.extend_time_link : messageTranslateKeys.forgot_token_link"></a>
  <wg-dialog *ngIf="isForgotTokenDialogOpen" [showDialog]="isForgotTokenDialogOpen" class="dialog allow-overflow"
    (dialogClosedEvent)="closeForgotAuthenticatorDialog()">
    <div dialog-title>{{ messageTranslateKeys.forgot_token | translate }}</div>
    <div dialog-content>
      <wg-forget-authenticator-form
        [authenticationPolicy]="authenticationPolicy"
        [authentication]="authentication"
        [allowExtendTime]="allowExtendTime"
        (loginResponse)="loginResponse.emit($event)">
      </wg-forget-authenticator-form>
    </div>
  </wg-dialog>
</div>
