<wg-dialog [showDialog]="true" class="custom-password-dialog allow-overflow" *ngIf="passwordAuthenticationDialog" (dialogClosedEvent)="closeDialog()">
  <div dialog-title class="dialog-title">{{ title | translate }}</div>

  <div dialog-content>
    <form #authenticationForm="ngForm" id="authenticationForm" (ngSubmit)="authenticateUserPassword()">
      <div *ngIf="errorMessage" class="error-message text-center">
        <wg-icon name="attention"></wg-icon>
        <span class="align-middle">{{ errorMessage | translate }}</span>
      </div>

      <p><b>{{ messageTranslateKeys.token_activation_step | translate }}</b></p>
      <p [innerHTML]="messageTranslateKeys.token_activation_step_information | translate"></p>

      <div class="form-group required password-group mt-4">
        <label class="password-label" for="password">{{ messageTranslateKeys.password | translate }}</label>
        <wg-hide-show-password>
          <input type="password" autofocus required class="form-control" XSSPatternValidator="false"
            name="password" id="password" [(ngModel)]="password" (ngModelChange)="cleanErrorMessage()"
            placeholder="{{ messageTranslateKeys.token_activation_enter_password | translate }}" autocomplete="off">
        </wg-hide-show-password>
        <show-errors [control]="authenticationForm.controls.password" keySuffix="password">
        </show-errors>
      </div>
    </form>
    <wg-http-error-notification *ngIf="errorMessage"></wg-http-error-notification>
  </div>

  <div dialog-footer>
    <div class="row ui-dialog-buttonpane no-gutters float-right">
      <button class="btn btn-primary" form="authenticationForm" [disabled]="authenticationForm.invalid"
        type="submit">
        {{ messageTranslateKeys.password_authentication_button_next | translate }}
      </button>
    </div>
  </div>
</wg-dialog>
