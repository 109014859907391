import { NgModule } from '@angular/core';

import { WgClickOutsideModule } from '@watchguard/wg-directives';

import { SharedModule } from '../shared/shared.module';

import { ApplicationsComponent } from './applications.component';
import { ApplicationsService } from './applications.service';
import { PasswordModule } from '../password/password.module';
import { SspTokenModule } from '../self-service-portal/ssp-token.module';
import { SessionModule } from '../session/session.module';

@NgModule({
  imports: [
    PasswordModule,
    SspTokenModule,
    SharedModule,
    SessionModule,
    WgClickOutsideModule
  ],
  declarations: [
    ApplicationsComponent
  ],
  providers: [
    ApplicationsService
  ],
})
export class ApplicationsModule { }
