export const MESSAGE_TRANSLATE_KEYS = {
  user_name: 'auth_sso_login_username',
  user_name_or_email: 'auth_sso_login_with_username_or_email',
  user_name_or_email_label: 'auth_sso_login_username_or_email',
  email: 'auth_sso_login_email',
  password: 'auth_sso_login_password',
  qrcode_challenge: 'auth_sso_login_qrcode_challenge',
  otp: 'auth_sso_login_otp',
  next: 'auth_sso_login_next',
  back: 'auth_sso_login_back',
  signin_options: 'auth_sso_login_signin_options',
  push: 'auth_sso_login_push',
  qrcode: 'auth_sso_login_qrcode',
  one_time_password: 'auth_sso_login_one_time_password',
  send_push: 'auth_sso_login_send_push',
  send_otp: 'auth_sso_login_send_otp',
  send_qrcode: 'auth_sso_login_send_qrcode',
  forget_token: 'auth_sso_login_forget_token',
  finish: 'auth_sso_login_finish',
  verify_code: 'auth_sso_login_qrcode_verify_code',
  authentication_by_otp: 'auth_sso_login_authentication_by_otp',
  authentication_by_otp_upgrade: 'auth_sso_login_authentication_by_otp_upgrade',
  authentication_by_qrcode: 'auth_sso_login_authentication_by_qrcode',
  authentication_by_qrcode_upgrade: 'auth_sso_login_authentication_by_qrcode_upgrade',
  choose_type_authentication: 'auth_sso_login_choose_authentication_type',
  choose_one_option_bellow: 'auth_ssp_login_choose_one_option_bellow',
  choose_type_authentication_upgrade: 'auth_sso_login_choose_authentication_type_upgrade',
  error: 'auth_sso_login_error',
  permission_denied: 'auth_sso_login_error_permission_danied',
  code_validate_invalid: 'auth_sso_login_error_code_validate_invalid',
  otp_invalid: 'auth_sso_login_error_otp_invalid',
  user_invalid: 'auth_sso_login_error_user_invalid',
  field_invalid: 'auth_sso_login_error_field_invalid',
  account_name: 'auth_sso_resources_account_name',
  change_password: 'auth_sso_resources_change_password',
  activate_hardware_token: 'auth_ssp_activate_hardware_token',
  activate_mobile_token: 'auth_ssp_activate_mobile_token',
  token_activation_step: 'auth_ssp_token_activation_step',
  token_activation_step_information: 'auth_ssp_token_activation_step_information',
  token_activation_enter_password: 'auth_ssp_token_activation_enter_password',
  without_resources: 'auth_sso_resources_without_resources',
  without_resources_search: 'auth_sso_resources_without_resources_search',
  logout: 'auth_sso_resources_logout',
  logout_invalid: 'auth_sso_resources_logout_fail',
  password_invalid: 'auth_sso_login_password_invalid',
  error_open_sp: 'auth_sso_resources_error_open_sp',
  error_open_sp_with_strong_authentication: 'auth_sso_resources_error_weak_authentication',
  invalid_customer_name: 'auth_sso_resources_invalid_customer',
  error_login_otp_password: 'auth_sso_login_error_invalid_otp_or_password',
  sent_push: 'auth_sso_login_sent_push',
  unexpected_error: 'auth_sso_login_error_unexpected',
  sent_email: 'auth_sso_login_sent_email',
  current_password: 'auth_sso_login_current_password',
  enter_current_password: 'auth_sso_login_enter_current_password',
  new_password_title: 'auth_sso_login_new_password_title',
  new_password: 'auth_sso_login_new_password',
  enter_new_password: 'auth_sso_login_enter_new_password',
  confirm_password: 'auth_sso_login_confirm_password',
  enter_confirm_password: 'auth_sso_login_enter_confirm_password',
  ok: 'auth_sso_login_ok',
  save: 'auth_sso_login_save',
  cancel: 'auth_sso_login_cancel',
  forgot_password: 'auth_sso_login_forgot_password',
  password_successfully_defined: 'auth_sso_login_password_successfully_defined',
  password_successfully_changed: 'auth_sso_login_password_successfully_changed',
  success: 'auth_sso_success',
  forgot_token_information_help: 'auth_sso_resources_forgot_token_information_help',
  forgot_token_information_description: 'auth_sso_resources_forgot_token_information_description',
  forgot_token_information_step_one: 'auth_sso_resources_forgot_token_information_step_one',
  forgot_token_information_step_two: 'auth_sso_resources_forgot_token_information_step_two',
  forgot_token_information_step_three: 'auth_sso_resources_forgot_token_information_step_three',
  forgot_token_information_step_four: 'auth_sso_resources_forgot_token_information_step_four',
  forgot_token_information_finish: 'auth_sso_resources_forgot_token_information_finish',
  forgot_token_header: 'auth_sso_resources_forgot_token_header_title',
  forgot_token_header_upgrade: 'auth_sso_resources_forgot_token_header_title_upgrade',
  forgot_token_link: 'auth_sso_resources_forgot_token_link',
  extend_time_link: 'auth_sso_resources_forgot_token_extend_time_link',
  forgot_token: 'auth_sso_resources_forgot_token_title',
  forgot_token_activation_code: 'auth_sso_resources_forgot_token_challenge',
  forgot_token_hour: 'auth_sso_resources_forgot_token_hour',
  forgot_token_hour_ph: 'auth_sso_resources_forgot_token_hour_placeholder',
  forgot_token_hour_title: 'auth_sso_resources_forgot_token_hour_title',
  forgot_token_response: 'auth_sso_resources_forgot_token_response',
  forgot_token_response_ph: 'auth_sso_resources_forgot_token_response_placeholder',
  forgot_token_response_title: 'auth_sso_resources_forgot_token_response_title',
  pending_token_activation_title: 'auth_sso_resources_pending_token_activation_title',
  pending_token_activation_link: 'auth_sso_resources_pending_token_activation_link',
  pending_token_activation_usage: 'auth_sso_resources_pending_token_activation_usage',
  pending_token_activation_qr_code_usage: 'auth_sso_resources_pending_token_activation_qr_code_usage',
  pending_token_activation_user_non_mfa_message:'auth_sso_resources_pending_token_activation_user_non_mfa_message',
  pending_token_activation_user_non_mfa_action: 'auth_sso_resources_pending_token_activation_user_non_mfa_action',
  pending_token_activation_mobile_link_usage: 'auth_sso_resources_pending_token_activation_mobile_link_usage',
  pending_token_activation_mobile_link_activate: 'auth_sso_resources_pending_token_activation_mobile_link_activate',
  pending_token_activation_button_next: 'auth_sso_resources_pending_token_activation_button_next',
  pending_token_activation_button_close: 'auth_sso_resources_pending_token_activation_button_close',
  forgot_token_validate: 'auth_sso_resources_forgot_token_validate_button',
  forgot_token_successfully: 'auth_sso_resources_forgot_token_successfully',
  forgot_token_error: 'auth_sso_resources_forgot_token_error',
  error_code: 'auth_sso_login_error_code',
  request_id: 'auth_sso_login_request_id',
  defaultErrorMessage: 'auth_sso_login_error_default',
  token_activation_title: 'auth_sso_token_activation_title',
  token_activation_activate: 'auth_sso_token_activation_activate',
  token_activation_title_description: 'auth_sso_token_activation_title_description',
  token_activation_app_download: 'auth_sso_token_activation_app_download',
  token_activation_app_download_description: 'auth_sso_token_activation_app_download_description',
  token_activation_app_link: 'auth_sso_token_activation_app_link',
  token_activation_app_link_description: 'auth_sso_token_activation_app_link_description',
  token_activation_app_qrcode: 'auth_sso_token_activation_app_qrcode',
  token_activation_app_qrcode_warning: 'auth_sso_token_activation_app_qrcode_warning',
  token_activation_app_qrcode_error: 'auth_sso_token_activation_app_qrcode_error',
  password_authentication_button_next: 'auth_ssp_password_authentication_button_next',
  password_authentication_second_step: 'auth_ssp_password_authentication_button_second_step',
  mobile_token_activation_title: 'auth_ssp_mobile_token_activation_title',
  mobile_token_activation_finish_alert: 'auth_ssp_mobile_token_activation_finish_alert',
  qrcode_activation_mobile_link_usage: 'auth_ssp_qrcode_activation_mobile_link_usage',
  qrcode_activation_mobile_link_activate: 'auth_ssp_qrcode_activation_mobile_link_activate',
  qrcode_activation_usage: 'auth_ssp_qrcode_activation_usage',
  hardware_token_activation_title: 'auth_ssp_hardware_token_activation_title',
  hardware_token_activation_second_step: 'auth_ssp_hardware_token_activation_token_second_step',
  hardware_token_activation_otp_info_popover: 'auth_ssp_hardware_token_otp_info_popover',
  hardware_token_activation_serial_number_info_popover: 'auth_ssp_hardware_token_token_serial_number_info_popover',
  ssp_token_error_generating_qrcode: 'auth_ssp_token_error_generating_qrcode',
  ssp_token_error_initializing_hardware_token_page: 'auth_ssp_token_error_initializing_hardware_token_page',
  token_serial_number: 'auth_ssp_hardware_token_activation_token_serial_number',
  enter_token_serial_number: 'auth_ssp_hardware_token_activation_enter_token_serial_number',
  enter_otp: 'auth_ssp_hardware_token_activation_enter_otp',
  ssp_hardware_token_successfully_activated: 'auth_ssp_hardware_token_successfully_activated',
  search: 'auth_sso_resources_search',
};

export const ERROR_PREFIX = {
  signin: 'auth_sso_login_error_signin_',
  authentication_policy: 'auth_sso_login_error_authentication_type_',
  authentication_push: 'auth_sso_login_error_authentication_push_',
  authentication_password: 'auth_sso_login_error_authentication_password_',
  authentication_otp: 'auth_sso_login_error_authentication_otp_',
  authentication_qrcode: 'auth_sso_login_error_authentication_qrcode_',
  authentication_qrcode_invalid_code: 'auth_sso_login_error_qrcode_response_invalid_code',
  push_result: 'auth_sso_login_error_push_result_',
  pending_token: 'auth_sso_login_error_pending_token_',
  push_result_timeout: 'auth_sso_login_error_push_result_timeout',
  qrcode_response: 'auth_sso_login_error_qrcode_response_',
  logout: 'auth_sso_login_error_logout_',
  set_password: 'auth_sso_login_error_set_password_',
  forget_password: 'auth_sso_login_error_forget_password_',
  forget_authenticator: 'auth_sso_login_error_forget_authenticator_',
  change_password: 'auth_sso_login_error_change_password_',
  open_service_provder: 'auth_sso_login_error_open_service_provider_',
  ssp_password_authentication: 'auth_ssp_password_authentication_error_',
  ssp_qrcode_activation: 'auth_ssp_qrcode_activation_error_',
  ssp_hardware_token_activation: 'auth_ssp_hardware_token_activation_error_'
};

export const MESSAGE_SUCCESS = {
  send_email_forget_password: 'auth_sso_login_sent_email',
  logout_success: 'auth_sso_logout_success',
  password_defined: 'auth_sso_login_password_successfully_defined'
};

export const BACKEND_CUSTOM_ERRORS = [
  '201005001', '201005002', '201005003', '201005004', '201005005', '201005006', '201005007', '201005008', '201005009',
  '201005010', '201005011', '201005012', '201005014', '201005019', '201005025',
  '201025012',
  '201026000', '201026001', '201026002', '201026003', '201026004', '201026005', '201026006',
  '201035001',
  '201045000', '201045001', '201045002', '201045003', '201045004', '201045005', '201045006', '201045007', '201045008', '201045009',
  '201045010', '201045018',
  '201055001', '201055002', '201055003', '201055004', '201055005', '201055006', '201055007', '201055008',
  '201085000', '201085001', '201085002', '201085003', '201085004', '201085005', '201085006', '201085007', '201085008',
  '201085012', '201085013', '201085014', '201085015', '201085016', '201085017',
  '201086001', '201086004', '201086006',
  '201088001', '201105008',
  '202001003',
  '202001040', '202001041',
  '202006003', '202006005',
  '203005013',
  '210005001', '210005002', '210005003',
  '210002002', '210002004', '210002010',
  '210003013', '210003015', '210003018', '210003019', '210003020', '210003025', '210003027', '210003028', '210003029',
  '210003030', '210003031', '210003034', '210003035', '210003037', '210003039', '210003041', '210003044',
  '300004001', '300004002', '300004003', '300004004', '300004005', '300004006', '300004007', '300004008', '300004009',
  '300004010', '300004011', '300004012', '300004013', '300004014',
  '300005001', '300005002', '300005007', '300005008', '300005009',
  '300005010', '300005011', '300005012', '300005013', '300005014', '300005015', '300005017', '300005018', '300005019',
  '300006001', '300006002', '300006007', '300006008', '300006009',
  '300006010', '300006011', '300006012', '300006013',
  '300007001', '300007002', '300007003', '300007004', '300007005', '300007006', '300007007', '300007008', '300007009',
  '300012001', '300012002', '300012003', '300012004', '300012005', '300012006', '300012007', '300012008'
];
