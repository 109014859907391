import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BrowserService, MESSAGE_SUCCESS, MESSAGE_TRANSLATE_KEYS, PATH } from '../../shared/';
import { PasswordService } from '../password-service';

@Component({
  selector: 'wg-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  @Input() accountId: string;
  @Input() username: string;
  @Output() errorResponse = new EventEmitter();

  serviceProvider: string;
  forgetPassword: boolean;
  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  constructor(
    private activatedRoute: ActivatedRoute,
    public setPasswordService: PasswordService,
    private router: Router,
    private browserService: BrowserService
  ) {
    this.serviceProvider = activatedRoute.snapshot.queryParams.serviceProvider;
  }

  sendEmailToRedefinePassword() {
    this.setPasswordService
      .forgetPassword(this.accountId, this.username)
      .subscribe({
        next: () => this.handleSendEmailSuccess(),
        error: (error: HttpErrorResponse) => this.errorResponse.emit(error)
      });
  }

  private handleSendEmailSuccess() {
    const queryParams = {
      serviceProvider: this.serviceProvider
    };

    this.browserService.setSuccessMessage(MESSAGE_SUCCESS.send_email_forget_password);
    this.router.navigate(['..', PATH.SUCESSFULLY_MESSAGE], {relativeTo: this.activatedRoute, queryParams });
  }
}
