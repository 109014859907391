<wg-forget-authenticator-alert
  [authenticationPolicy]="authenticationPolicy"
  [buttonLabel]="messageTranslateKeys.forgot_token | translate">
</wg-forget-authenticator-alert>
<form #loginForm="ngForm" (ngSubmit)="loginQRCode(loginForm.valid)">
    <div class="form-group qrcode-image-container" #overlayElement>
        <img *ngIf="!!qrCodeImage" [src]="qrCodeImage" class="qrcode-image" alt="QR Code">
        <img *ngIf="!qrCodeImage && qrCodeImageError" class="qrcode-image-error" [src]="errorImage" alt="QR Code load error">
    </div>
    <div *ngIf="loginInvalid" class="error-message">
        <wg-icon name="attention"></wg-icon>
        <span class="align-middle" [translate]="errorMessage"></span>
    </div>
    <div class="form-group custom-form-group required" *ngIf="!qrCodeImageError">
        <label for="qrcode" [translate]="messageTranslateKeys.verify_code"></label>
        <input autofocus type="text" name="loginForm.qrCodeResponse" OnlyNumber minlength="6" maxlength="6" rangeValidator autocomplete="off"
            #qrCodeResponse="ngModel" [(ngModel)]="qrCode.qrCodeResponse" (ngModelChange)="cleanErrorMessage()"
            required class="input-block-level" placeholder="{{ messageTranslateKeys.verify_code | translate}}">
        <show-errors [control]="qrCodeResponse" keySuffix="qrcode"></show-errors>
    </div>
    <div class="form-actions" *ngIf="!qrCodeImageError">
        <button class="btn btn-primary login-btn" type="submit" [disabled]="loginForm.invalid" [translate]="messageTranslateKeys.finish"></button>
    </div>
</form>
<wg-forget-authenticator
    [authenticationPolicy]="authenticationPolicy"
    [authentication]="authentication"
    (loginResponse)="handleLoginSuccess($event)">
</wg-forget-authenticator>
<wg-pending-token-activation
    [authentication]="authentication"
    (authenticationPolicyChange)="updateAuthenticationPolicy()">
</wg-pending-token-activation>
