<div>
  <div class="wg-logo" *ngIf="logo">
    <img [src]="logo" alt="WatchGuard Logo" (error)="loadDefaultImage()">
  </div>
  <div class="login-title" *ngIf="title">
    <h2 [translate]="title"></h2>
  </div>
  <div class="login-subtitle" *ngIf="subtitle">
    <h5><strong><span [translate]="verifyTypeAccess()"></span></strong>: {{ subtitle }}</h5>
  </div>
</div>
