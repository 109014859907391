<div>
  <div *ngIf="errorMessage" class="error-message">
    <wg-icon name="attention"></wg-icon>
    <span class="align-middle">{{ errorMessage | translate }}</span>
  </div>

  <wg-ssp-qrcode-activation
    [accountId]="accountId"
    [exhibitionType]="ExhibitionType.PAGE">
  </wg-ssp-qrcode-activation>

  <p *ngIf="this.qrCodeActivationComponent && this.qrCodeActivationComponent.qrCodeImage" class="finish_alert">
    {{ messageTranslateKeys.mobile_token_activation_finish_alert | translate }}
  </p>

  <button (click)="verifyTokenStatus()" class="btn btn-primary" type="button"
    *ngIf="this.qrCodeActivationComponent && this.qrCodeActivationComponent.qrCodeImage">
    {{ messageTranslateKeys.finish | translate }}
  </button>
</div>
