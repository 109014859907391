<div
  class="qrcode-container"
  [class.qrcode-container-for-dialog]="exhibitionType === ExhibitionType.DIALOG">
  <div *ngIf="errorMessage" class="error-message h-100 d-flex justify-content-center align-items-center">
    <wg-icon name="attention"></wg-icon>
    <span class="align-middle">{{ errorMessage | translate }}</span>
  </div>

  <p
    *ngIf="exhibitionType === ExhibitionType.DIALOG && errorMessage === null"
    class="message-title">
    {{ messageTranslateKeys.password_authentication_second_step | translate }}
  </p>

  <div class="form-group" *ngIf="(isMobileDevice || isTablet) && mobileActivationLink">
    <p class="activate-message">
      {{ messageTranslateKeys.qrcode_activation_mobile_link_usage | translate }}
    </p>
    <div class="text-center mobile-activation-link">
      <a class="btn btn-primary" [href]="mobileActivationLink">
        {{ messageTranslateKeys.qrcode_activation_mobile_link_activate | translate }}
      </a>
    </div>
  </div>

  <div class="form-group qrcode-content" *ngIf="!isMobileDevice && qrCodeImage">
    <p class="mb-0">
      {{ messageTranslateKeys.qrcode_activation_usage | translate }}
    </p>
    <div class="text-center">
      <img class="qrcode-image" alt="qrCode" [src]="qrCodeImage">
    </div>
  </div>
</div>
<wg-http-error-notification *ngIf="exhibitionType === ExhibitionType.DIALOG && errorMessage"></wg-http-error-notification>
