import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { WgNotificationsService } from '@watchguard/wg-notifications';

import { AutoUnsubscribe, ErrorHandlerService, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS } from '../../shared';
import { SspTokenService } from '../ssp-token.service';
import { Command, ExhibitionType, HardwareTokenActivationRequest, HardwareTokenActivationResponse } from '../ssp-token.model';
import { hardwareSuccessResponse, tokenSerialNumberRegex } from '../ssp-token.constants';

@Component({
  selector: 'wg-hardware-token-activation-dialog',
  templateUrl: './hardware-token-activation-dialog.component.html',
  styleUrls: ['hardware-token-activation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class HardwareTokenActivationDialogComponent {

  @Input() mustOpenHardwareTokenActivationDialog: boolean;
  @Input() accountId: string;
  @Input() username: string;
  @Output() closeHardwareTokenActivationDialog = new EventEmitter();

  ExhibitionType = ExhibitionType;

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  errorMessage: string = null;
  title = this.messageTranslateKeys.hardware_token_activation_title;

  isUserAuthenticated = false;
  serialNumber: string = null;
  otp: number;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private errorHandlerService: ErrorHandlerService,
    private sspTokenService: SspTokenService,
    private translateService: TranslateService,
    private notificationService: WgNotificationsService,
  ) { }

  activateHardwareToken() {
    this.sspTokenService
      .activateHardwareToken(this.accountId, this.hardwareTokenActivationRequest)
      .subscribe({
        next: (response: HardwareTokenActivationResponse) => {
          if (response && response.result === hardwareSuccessResponse) {
            this.closeDialog();
            const message = this.translateService.instant(MESSAGE_TRANSLATE_KEYS.ssp_hardware_token_successfully_activated);
            this.notificationService.success(message);
          } else {
            this.errorHandler(ERROR_PREFIX.ssp_hardware_token_activation,
              { error: { mainMessageKey: 'auth_sso_api_error_210003020' } } as HttpErrorResponse);
          }
        },
        error: (error: HttpErrorResponse) => this.errorHandler(ERROR_PREFIX.ssp_hardware_token_activation, error)
      });
  }

  shouldFooterButtonBeDisabled() {
    const otpMaxLength = 6;
    const isValidOtp = this.otp ? this.otp.toString().length === otpMaxLength : false;
    const isValidSerial = this.serialNumber ? tokenSerialNumberRegex.test(this.serialNumber) : false;
    return !(isValidOtp && isValidSerial);
  }

  onPasswordAuthenticated(error: HttpErrorResponse) {
    if (error) {
      this.errorHandler(ERROR_PREFIX.ssp_password_authentication, error);
    } else {
      this.isUserAuthenticated = true;
    }
  }

  closeDialog() {
    this.mustOpenHardwareTokenActivationDialog = false;
    this.cleanVariables();
    this.closeHardwareTokenActivationDialog.emit();
  }

  cleanErrorMessage() {
    this.errorMessage = null;
  }

  private cleanVariables() {
    this.otp = null;
    this.serialNumber = null;
    this.errorMessage = null;
    this.isUserAuthenticated = false;
  }

  private get hardwareTokenActivationRequest(): HardwareTokenActivationRequest {
    return {
      securityContextId: this.sspTokenService.securityContextId,
      verifier: this.sspTokenService.verifier,
      friendlySerialNumber: this.serialNumber,
      otp1: this.otp.toString(),
      command: Command.ACTIVATE,
    };
  }

  private errorHandler(requestType: string, httpErrorResponse: HttpErrorResponse) {
    this.errorMessage = this.errorHandlerService.getMessageError(
      requestType,
      httpErrorResponse
    );
    this.changeDetector.detectChanges();
  }

}
