import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { Authentication, IDP_LOGIN } from '../../authentication-form/authentication.model';
import { AutoUnsubscribe, BrowserService, ErrorHandlerService, ERROR_CODE, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS, PATH } from '../../shared';
import { QrCodeActivationComponent } from '../qrcode-activation/qrcode-activation.component';
import { SspTokenService } from '../ssp-token.service';
import { UserService } from '../../authentication-form/user/user.service';
import { HeaderService } from '../../login/header/header.service';
import { ExhibitionType, TokenErrorParam } from '../ssp-token.model';

@Component({
  selector: 'wg-ssp-mobile-token-activation',
  templateUrl: './mobile-token-activation.component.html',
  styleUrls: ['mobile-token-activation.component.scss'],
})
@AutoUnsubscribe()
export class MobileTokenActivationComponent implements OnInit, AfterViewInit {

  @ViewChild(QrCodeActivationComponent)
  qrCodeActivationComponent: QrCodeActivationComponent;

  ExhibitionType = ExhibitionType;

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  title = this.messageTranslateKeys.mobile_token_activation_title;

  errorMessage: string;
  authentication: Authentication;
  accountId: string;
  username: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private browserService: BrowserService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private sspTokenService: SspTokenService
  ) { }

  ngOnInit() {
    if (this.sspTokenService.securityContextId) {
      this.authentication = this.browserService.getItemLocalStorage(`${IDP_LOGIN}_authentication`);
      this.username = this.authentication ? this.authentication.user.username : null;
      this.accountId = this.authentication ? this.authentication.accountId : null;

      this.headerService.headerSubmit({
        title: this.messageTranslateKeys.mobile_token_activation_title,
        subtitle: this.username
      });
    } else {
      const navigationExtras: NavigationExtras = {
        relativeTo: this.activatedRoute,
        queryParams: { error: TokenErrorParam.QRCODE }
      };
      this.router.navigate(['..', PATH.SSP_TOKEN], navigationExtras);
    }
  }

  ngAfterViewInit() {
    if (this.sspTokenService.securityContextId && this.qrCodeActivationComponent) {
      this.qrCodeActivationComponent.getQrCodeData();
    }
  }

  verifyTokenStatus() {
    forkJoin([
      this.userService.fetchAuthenticationPolicy(this.authentication),
      this.userService.fetchTokenStatus(this.username, this.accountId)

    ]).subscribe({
      next: ([authenticationPolicy, tokenStatus]) => {
        let route = ['..'];
        if (!authenticationPolicy.isAuthenticationDeniedByPolicy) {
          route = tokenStatus.hasTokens ? ['..', PATH.VALIDATE] : ['..', PATH.SSP_TOKEN];
        }
        this.router.navigate(route, { relativeTo: this.activatedRoute });
      },
      error: (error: HttpErrorResponse) => {
        const isUnauthorized = this.errorHandlerService.verifiyErrorCode([ERROR_CODE.UNAUTHORIZED_RESOURCE], error);
        if (isUnauthorized) {
          this.router.navigate(['..'], { relativeTo: this.activatedRoute });
        } else {
          this.errorHandler(ERROR_PREFIX.authentication_policy, error);
        }
      }
    });
  }

  private errorHandler(requestType: string, httpErrorResponse: HttpErrorResponse) {
    this.errorMessage = this.errorHandlerService.getMessageError(
      requestType,
      httpErrorResponse
    );
  }

}
