import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';

import { TransactionData } from '../authentication-form/authentication.model';

@Injectable()
export class NavigatorInformationService {

  parser = new UAParser();

  getTransactionData(): TransactionData {
    return {
      osVersion: this.parser.getOS().name,
      browser: this.parser.getBrowser().name
    };
  }

  // TODO: Some tablets versions are not been recognized: https://github.com/faisalman/ua-parser-js/issues/690
  // A new check was added in version 2.0 (.withFeatureCheck()) to solve it. We can install it and test it when the version will be released.
  getDevice(): string {
    return this.parser.getDevice().type;
  }
}
