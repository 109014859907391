import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as QRCodeLib from 'qrcode';
import { finalize, tap } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { AuthenticationPolicy, QRCode } from '../../authentication-form/authentication.model';
import { HeaderService } from '../../login/header/header.service';
import {
  AutoUnsubscribe,
  BrowserService,
  ERROR_PREFIX,
  ErrorHandlerService,
  NavigatorInformationService,
  SamlFormService,
  DEFAULT_IMAGE,
} from '../../shared/';
import { ApplicationListService } from '../../applications/applications-list/application-list.service';
import { AuthenticationPolicyService } from '../authentication-policy.service';
import { AuthenticationComponent } from '../authentication.component';
import { QRCodeService } from './qrcode.service';

@Component({
  selector: 'wg-login-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
@AutoUnsubscribe()
export class QrcodeComponent extends AuthenticationComponent implements OnInit, OnDestroy {

  password: string;
  qrCode: QRCode;
  qrCodeImage: string;
  qrCodeImageError = false;
  errorImage = DEFAULT_IMAGE.ERROR;

  constructor(
    private authenticationPolicyService: AuthenticationPolicyService,
    private loaderService: LoaderService,
    public activatedRoute: ActivatedRoute,
    public errorHandleService: ErrorHandlerService,
    public router: Router,
    public browserService: BrowserService,
    public samlFormService: SamlFormService,
    public qrcodeService: QRCodeService,
    public navigatorInformationService: NavigatorInformationService,
    public headerService: HeaderService,
    public applicationListService: ApplicationListService
  ) {
    super(activatedRoute, router, errorHandleService, headerService, browserService, samlFormService, applicationListService);
  }

  ngOnInit() {
    this.qrCode = new QRCode();
    this.serviceProvider = this.qrcodeService.getServiceProvider();
    this.password = this.qrcodeService.getPassword();
    this.authentication = this.qrcodeService.getAuthentication();
    this.serviceProviderLogo = this.qrcodeService.getServiceProviderLogo();
    this.authenticationPolicy = this.qrcodeService.getAuthenticationPolicy();
    this.headerService.headerSubmit({
      title: this.messageTranslateKeys.authentication_by_qrcode,
      serviceProviderLogo: this.serviceProviderLogo,
      subtitle: this.authentication.user.username,
    });

    this.qrCodeLogin();
  }

  ngOnDestroy() {/* It should be here to use the AutoUnsubscribe decorator */ }

  loginQRCode(isValid: boolean) {
    if (isValid) {
      this.loaderService.showLoading();
      this.authentication.user.authentication['qrCodeResponse'] = this.qrCode.qrCodeResponse;
      this.qrcodeService
        .qrcodeResponse(this.authentication, this.authenticationPolicy.isUpgrade).pipe(
          finalize(() => this.loaderService.hideLoading()),
        )
        .subscribe({
          next: (response: any) => this.handleLoginSuccess(response),
          error: (error: HttpErrorResponse) => this.loginQRCodeFail(error)
        });
    }
  }

  errorHandle(requestType: string, error: HttpErrorResponse) {
    this.qrCodeImageError = true;
    this.loaderService.hideLoading();

    super.errorHandle(requestType, error);
  }

  // TODO: Validate if it is really necessary
  updateAuthenticationPolicy() {
    this.authenticationPolicyService
      .fetchAuthenticationPolicy(this.authentication, this.serviceProvider)
      .subscribe({
        next: (authenticationPolicy: AuthenticationPolicy) => {
          this.authenticationPolicy = authenticationPolicy;
          this.qrcodeService.setAuthenticationPolicy(authenticationPolicy);
        },
        error: (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_policy, error)
      });
  }

  private getQRCodeImage() {
    QRCodeLib
      .toDataURL(this.authentication.user.authentication['qrCodeCommand'],
        (err, image: any) => {
          this.qrCodeImage = image;
          this.loaderService.hideLoading();
        });
  }

  private loginQRCodeFail(errorResponse: HttpErrorResponse) {
    if (errorResponse.status === 401) {
      this.errorMessage = ERROR_PREFIX.authentication_qrcode_invalid_code;
      this.loginInvalid = true;
      this.qrCodeLogin();
    } else {
      this.errorHandle(ERROR_PREFIX.qrcode_response, errorResponse);
    }
  }

  private qrCodeLogin() {
    this.qrCodeImageError = false;
    this.qrCodeImage = null;

    this.setAuthenticationUser(false, this.password, null, true);
    const authenticationObj = this.getAuthenticationObj(this.navigatorInformationService.getTransactionData());

    this.loaderService.showLoading();

    this.qrcodeService
      .login(authenticationObj, this.authenticationPolicy.isUpgrade)
      .pipe(
        tap((response: any) => {
          this.authentication.user.authentication = response;
          this.browserService.saveAuthentication(this.qrcodeService.getServiceProvider(), this.authentication);
        })
      )
      .subscribe({
        next: () => this.getQRCodeImage(),
        error: (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_qrcode, error)
      });
  }
}
