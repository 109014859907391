<div class="error-panel">
  <div class="wg-logo">
    <img [src]="errorImage" alt="Error">
  </div>
  <div class="login-title">
    <h2>{{ messageTranslateKeys | translate }}</h2>
  </div>

  <div *ngIf="isRdWeb" class="rdweb-container">
    <button type="button" class="btn btn-rdweb btn-danger col-1" (click)="retry()">
      {{ 'auth_sso_resources_try_again' | translate }}
    </button>
    <button type="button" class="btn btn-rdweb btn-danger col-1" (click)="logout()">
      {{ 'auth_sso_resources_logout' | translate }}
    </button>
  </div>

  <wg-http-error-notification></wg-http-error-notification>
</div>
