import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { WgNotificationsService } from '@watchguard/wg-notifications';

import { MESSAGE_TRANSLATE_KEYS, ERROR_PREFIX } from '../../shared/ui-strings';
import { AutoUnsubscribe } from '../../shared/auto.unsubscribe.decorator';
import { PasswordService } from '../password-service';
import { ChangePassword } from '../password.model';
import { ErrorHandlerService } from '../../shared/error-handler.service';

@Component({
  selector: 'wg-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
@AutoUnsubscribe()
export class EditPasswordComponent implements OnInit {

  @Input() editPasswordDialog: boolean;
  @Input() accountId: string;
  @Input() username: string;
  @Output() closeEditDialog = new EventEmitter();

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  changePassword: ChangePassword;
  confirmPassword: string;
  errorMessage: string = null;
  loadingSubscription: Subscription;
  isLoading: boolean;

  constructor(
    private notificationService: WgNotificationsService,
    private translate: TranslateService,
    private errorHandlerService: ErrorHandlerService,
    public router: Router,
    public passwordService: PasswordService
  ) { }

  ngOnInit() {
    this.changePassword = new ChangePassword();
  }

  closeDialog() {
    this.editPasswordDialog = false;
    this.errorMessage = null;
    this.closeEditDialog.emit(this.editPasswordDialog);
    this.changePassword = new ChangePassword();
  }

  saveNewPassword(isValid: boolean) {
    if (isValid) {
      this.changePassword.login = this.username;
      this.passwordService
        .changePassword(this.changePassword, this.accountId)
        .subscribe({
          next: () => this.handleChangePasswordSuccess(),
          error: (error: HttpErrorResponse) => this.errorHandler(ERROR_PREFIX.change_password, error)
        });
    }
  }

  cleanErrorMessage() {
    this.errorMessage = null;
  }

  private handleChangePasswordSuccess() {
    this.closeDialog();
    this.notificationService.success(this.translate.instant(this.messageTranslateKeys.password_successfully_changed));
  }

  private errorHandler(requestType: string, httpErrorResponse: HttpErrorResponse) {
    this.errorMessage = this.errorHandlerService.getMessageError(requestType, httpErrorResponse);
  }
}
