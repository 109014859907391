import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, switchMap } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { AuthenticationComponent } from '../../../authentication-form/authentication.component';
import { Authentication, AuthenticationPolicy, IDP_LOGIN } from '../../../authentication-form/authentication.model';
import { AuthenticatorForgottenService } from '../../../authentication-form/authenticator-forgotten/authenticator-forgotten.service';
import { ForgetAuthenticationForm, ForgottenAuthenticatorActivationRequest } from '../../../models/authenticator';
import { AutoUnsubscribe, BrowserService, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS, PATH, SamlFormService } from '../../../shared';
import { ErrorHandlerService } from '../../../shared/error-handler.service';
import { ApplicationListService } from '../../../applications/applications-list/application-list.service';
import { HeaderService } from '../../header/header.service';
import { fadeAnimation } from './../../../shared/animations';
import { ForgetAuthenticatorService } from './../forget-authenticator.service';

@Component({
  selector: 'wg-forget-authenticator-form',
  templateUrl: './forget-authenticator-form.component.html',
  styleUrls: ['./forget-authenticator-form.component.scss'],
  animations: [fadeAnimation]
})
@AutoUnsubscribe()
export class ForgetAuthenticatorFormComponent extends AuthenticationComponent implements OnInit {
  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  @Input() authenticationPolicy: AuthenticationPolicy;
  @Input() authentication: Authentication;
  @Input() allowExtendTime: boolean;
  @Output() loginResponse = new EventEmitter();

  resourceName: string;
  isForgetTokenInformation: boolean;
  isLoading: boolean;
  isForgotTokenDialogOpen: boolean;
  randomChallenge: string;
  challenge: string;
  forgotTokenRequest: ForgetAuthenticationForm = <ForgetAuthenticationForm>{};
  errorMessage: string;

  constructor(
    private errorHandlerService: ErrorHandlerService,
    activatedRoute: ActivatedRoute,
    router: Router,
    private forgetAuthenticatorService: ForgetAuthenticatorService,
    private loaderService: LoaderService,
    private authenticatorForgottenService: AuthenticatorForgottenService,
    headerService: HeaderService,
    browserService: BrowserService,
    samlFormService: SamlFormService,
    public applicationListService: ApplicationListService
  ) {
    super(activatedRoute, router, errorHandlerService, headerService, browserService, samlFormService, applicationListService);
    this.allowExtendTime = false;
    this.resourceName = this.activatedRoute.snapshot.queryParams.serviceProvider;
  }

  ngOnInit() {
    this.authenticationPolicy = this.authenticationPolicy || this.authenticatorForgottenService.getAuthenticationPolicy();
    this.authentication = this.authentication || this.authenticatorForgottenService.getAuthentication();

    if (this.activatedRoute.snapshot.queryParams.theme) {
      this.headerService.headerSubmit({
        title: this.messageTranslateKeys.forget_token,
        subtitle: this.authentication.user.username,
      });
    }

    if (this.authenticationPolicy && this.authenticationPolicy.isAuthenticatorForgotten && !this.allowExtendTime) {
      this.navigateToAuthenticatorForgottenPage();
    } else {
      this.forgotTokenRequest = {} as ForgetAuthenticationForm;
      const randomChallenge = this.generateRandomChallenge();
      this.randomChallenge = this.formatChallenge(randomChallenge);
      this.challenge = randomChallenge;
      this.isForgotTokenDialogOpen = true;
    }
  }

  isForgetTokenInformationToggle() {
    this.isForgetTokenInformation = !this.isForgetTokenInformation;
  }

  closeForgotAuthenticatorDialog() {
    this.isForgotTokenDialogOpen = false;
    this.isForgetTokenInformation = false;
  }

  isShowLinkForgetAuthentication(): boolean {
    if (this.authenticationPolicy) {
      const { otp, qrCode, push } = this.authenticationPolicy;
      return (otp || qrCode || push);
    }
    return false;
  }

  validateAuthenticationCode() {
    this.errorMessage = null;
    this.loaderService.showLoading();
    this.setAuthenticationUser(false, this.forgotTokenRequest.password, null, false);
    const forgottenAuthenticatorActivationRequest = this.generateForgottenAuthenticatorActivationRequest();

    this.forgetAuthenticatorService.validateAuthenticationCode(forgottenAuthenticatorActivationRequest)
      .pipe(
        switchMap(() => this.forgetAuthenticatorService.loginMFAByPass(this.authentication, this.authenticationPolicy.isUpgrade)),
        finalize(() => this.loaderService.hideLoading())
      )
      .subscribe({
        next: (response: any) => {
          this.handleLoginSuccess(response);
          this.isForgotTokenDialogOpen = false;
        },
        error: (error: HttpErrorResponse) => this.errorHandler(ERROR_PREFIX.forget_authenticator, error)
      });
  }

  private generateForgottenAuthenticatorActivationRequest(): ForgottenAuthenticatorActivationRequest {
    const request = {
      accountId: this.authentication.accountId,
      user: {
        username: this.authentication.user.username,
        authentication: {
          password: this.forgotTokenRequest.password,
        },
        forgottenAuthenticatorActivationData: {
          challenge: this.challenge,
          period: this.forgotTokenRequest.period,
          authenticationCode: this.forgotTokenRequest.authenticationCode
        }
      }
    } as ForgottenAuthenticatorActivationRequest;
    if (this.isSpInitiated()) {
      request.resource = { resourceName: this.resourceName };
      request.saml = this.getSamlRequest();
    }
    return request;
  }

  private isSpInitiated(): boolean {
    return this.resourceName !== IDP_LOGIN;
  }

  private getSamlRequest() {
    const { samlRequest, relayState, sigAlg, signature } = this.authentication.saml;
    return {
      samlRequest,
      relayState,
      sigAlg,
      signature
    };
  }

  private errorHandler(requestType: string, httpErrorResponse: HttpErrorResponse) {
    this.errorMessage = this.errorHandlerService.getMessageError(requestType, httpErrorResponse);
  }

  private generateRandomChallenge(): string {
    const randomChallenge = String(Math.random());
    return randomChallenge.slice(2, 8);
  }

  private formatChallenge(randonChallenge: string): string {
    return `${randonChallenge.substring(0, 3)} ${randonChallenge.substring(3, 6)}`;
  }

  private navigateToAuthenticatorForgottenPage() {
    this.router.navigate(['..', PATH.FORGOTTEN], {
      replaceUrl: true,
      relativeTo: this.activatedRoute,
      queryParams: { serviceProvider: this.resourceName }
    });
  }
}
