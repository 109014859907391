<form #passwordForm="ngForm" (ngSubmit)="validatePassword(passwordForm)">
  <div *ngIf="loginInvalid" class="error-message">
      <wg-icon name="attention"></wg-icon>
      <span class="align-middle" [translate]="errorMessage"></span>
  </div>
  <div class="form-group custom-form-group required">
      <label for="new_password" [translate]="messageTranslateKeys.new_password"></label>
      <wg-hide-show-password>
          <input autofocus type="password" validateEqual="confirmPassword" reverse="true" minlength="6" maxlength="50" class="input-block-level"
              #password="ngModel" XSSPatternValidator required [(ngModel)]="setPassword.password" name="password" placeholder="{{ messageTranslateKeys.new_password | translate}}"
              autocomplete="off">
      </wg-hide-show-password>
      <show-errors [control]="passwordForm.controls.password" keySuffix="password" [customMessage]="compromisedPasswordError"></show-errors>
  </div>

  <div class="form-group custom-form-group required">
      <label for="confirmPassword" [translate]="messageTranslateKeys.confirm_password"></label>
      <wg-hide-show-password>
          <input type="password" validateEqual="password" reverse="false" maxlength="50" class="input-block-level" #confirmPassword="ngModel"
              XSSPatternValidator required [(ngModel)]="setPassword.confirmPassword" name="confirmPassword" placeholder="{{ messageTranslateKeys.confirm_password | translate}}"
              autocomplete="off">
      </wg-hide-show-password>
      <show-errors [control]="confirmPassword" keySuffix="confirm_password"></show-errors>
  </div>
  <button class="btn btn-primary login-btn" type="submit" [translate]="messageTranslateKeys.save"></button>
</form>
