import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Command, ExhibitionType, MobileTokenActivationRequest, MobileTokenActivationResponse } from '../ssp-token.model';
import { SspTokenService } from '../ssp-token.service';
import {
  AutoUnsubscribe,
  ErrorHandlerService,
  ERROR_PREFIX,
  MESSAGE_TRANSLATE_KEYS,
  NavigatorInformationService
} from '../../shared';

@Component({
  selector: 'wg-ssp-qrcode-activation',
  templateUrl: './qrcode-activation.component.html',
  styleUrls: ['qrcode-activation.component.scss']
})
@AutoUnsubscribe()
export class QrCodeActivationComponent implements OnInit {

  @Input() accountId: string;
  @Input() exhibitionType: ExhibitionType;

  ExhibitionType = ExhibitionType;

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  isMobileDevice = true;
  isTablet = true;
  errorMessage: string;
  qrCodeImage: SafeResourceUrl;
  mobileActivationLink: SafeResourceUrl;

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private sanitizer: DomSanitizer,
    private sspTokenService: SspTokenService,
    private navigatorInformationService: NavigatorInformationService
  ) { }

  ngOnInit() {
    const device = this.navigatorInformationService.getDevice();
    this.isMobileDevice = device === "mobile";
    this.isTablet = device === "tablet";
  }

  getQrCodeData() {
    this.sspTokenService
      .getQrCodeData(this.accountId, this.mobileTokenActivationRequest)
      .subscribe({
        next: (response: MobileTokenActivationResponse) => {
          this.qrCodeImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + response.qrCodeImage);
          this.mobileActivationLink = this.sanitizer.bypassSecurityTrustResourceUrl(response.mobileActivationLink);
          this.errorMessage = null;
        },
        error: (error: HttpErrorResponse) => this.errorHandler(ERROR_PREFIX.ssp_qrcode_activation, error)
      });
  }

  cleanVariables() {
    this.errorMessage = undefined;
    this.qrCodeImage = null;
    this.mobileActivationLink = null;
  }

  private get mobileTokenActivationRequest(): MobileTokenActivationRequest {
    return {
      securityContextId: this.sspTokenService.securityContextId,
      verifier: this.sspTokenService.verifier,
      command: Command.ACTIVATE,
    };
  }

  private errorHandler(requestType: string, httpErrorResponse: HttpErrorResponse) {
    this.errorMessage = this.errorHandlerService.getMessageError(
      requestType,
      httpErrorResponse
    );
  }

}
