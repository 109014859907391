import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { HeaderService } from '../../login/header/header.service';
import { AutoUnsubscribe, BrowserService, ERROR_PREFIX, ErrorHandlerService, MESSAGE_TRANSLATE_KEYS, SamlFormService } from '../../shared/';
import { ApplicationListService } from '../../applications/applications-list/application-list.service';
import { AuthenticationPolicyService } from '../authentication-policy.service';
import { AuthenticationComponent } from '../authentication.component';
import { AuthenticationPolicy } from '../authentication.model';
import { AuthenticatorForgottenService } from './authenticator-forgotten.service';

@Component({
  templateUrl: './authenticator-forgotten.component.html',
  styles: [`
    input[type="password"],
    input[type="text"] {
        width: 290px;
    }
  `]
})
@AutoUnsubscribe()
export class AuthenticatorForgottenComponent extends AuthenticationComponent implements OnInit {

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  password: string;
  authenticationPolicy: AuthenticationPolicy;

  constructor(
    public activatedRoute: ActivatedRoute,
    public errorHandlerService: ErrorHandlerService,
    public headerService: HeaderService,
    public browserService: BrowserService,
    public samlFormService: SamlFormService,
    public router: Router,
    private loaderService: LoaderService,
    private authenticatorForgottenService: AuthenticatorForgottenService,
    public applicationListService: ApplicationListService,
    private authenticationPolicyService: AuthenticationPolicyService
  ) {
    super(activatedRoute, router, errorHandlerService, headerService, browserService, samlFormService, applicationListService);
  }

  ngOnInit() {
    this.authenticationPolicy = this.authenticatorForgottenService.getAuthenticationPolicy();
    this.authentication = this.authenticatorForgottenService.getAuthentication();
    this.serviceProviderLogo = this.authenticatorForgottenService.getServiceProviderLogo();
    this.headerService.headerSubmit({
      title: this.messageTranslateKeys.forgot_token_header,
      serviceProviderLogo: this.serviceProviderLogo,
      subtitle: this.authentication.user.username,
    });
  }

  onSubmit(isFormValid: boolean) {
    if (isFormValid) {
      this.login();
    }
  }

  // TODO: Validate if it is really necessary
  updateAuthenticationPolicy() {
    this.authenticationPolicyService
      .fetchAuthenticationPolicy(this.authentication, this.serviceProvider)
      .subscribe({
        next: (authenticationPolicy: AuthenticationPolicy) => {
          this.authenticationPolicy = authenticationPolicy;
          this.authenticatorForgottenService.setAuthenticationPolicy(authenticationPolicy);
        },
        error: (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_policy, error)
      });
  }

  private login() {
    this.loaderService.showLoading();
    this.setAuthenticationUser(false, this.password, null, false);
    this.authenticatorForgottenService
      .login(this.authentication).pipe(
        finalize(() => this.loaderService.hideLoading())
      )
      .subscribe({
        next: (response: any) => this.handleLoginSuccess(response),
        error: (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_password, error)
      });
  }
}
