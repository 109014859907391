export class Authentication {
  accountId: string;
  saml?: Saml;
  user?: User;
  resourceName?: string;
  securityContext?: SecurityContext;
  transactinData?: TransactionData;
  geolocationData?: GeolocationData;
}

export interface GeolocationData {
  latitude: number;
  longitude: number;
  accuracy: number;
}

export interface TransactionData {
  osVersion: string;
  browser: string;
}

export class Saml {
  id?: string;
  selectedSp?: string;
  samlRequest?: string;
  relayState?: string;
  sigAlg?: string;
  signature?: string;
  spInitResourceName?: string;
}

export class User {
  email?: string;
  fullName?: string;
  username: string;
  authentication?: AuthenticationPolicyChose | QRCodeAuthentication;
}

export interface SecurityContext {
  id: string;
}

export interface QRCodeAuthentication {
  qrCodeCommand: string;
  qrCodeResponse: string;
  qrCodeTransactionId: string;
}

export interface AuthenticationPolicyChose {
  push: boolean;
  qrCode: boolean;
  oneTimePassword?: number;
  password?: string;
}

export class AuthenticationPolicy {
  password: boolean;
  push: boolean;
  qrCode: boolean;
  otp: boolean;
  isAuthenticatorForgotten?: boolean;
  isUpgrade?: boolean;
  authenticatorForgottenRemainingTimeInSeconds?: number;
  isAuthenticationDeniedByPolicy?: boolean;
  pendingToken: boolean;
  hasForgotPassword: boolean;
}

export class QRCode {
  qrCodeTransactionId: string;
  qrCodeCommand: string;
  qrCodeResponse: number;
}

export class OTPCode {
  password: string;
  oneTimePassword: number;
}

export class SessionIntegration {
  pairingId: string;
  region: string;
}

export const PASSWORD = 'PASSWORD';
export const PUSH = 'PUSH';
export const QR_CODE = 'QR CODE';
export const OTP = 'OTP';

export const IDP_LOGIN = 'Idp';

export interface TokenStatus {
  hasTokens: boolean;
}
