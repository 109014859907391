<div>
  <wg-ssp-password-authentication
    [passwordAuthenticationDialog]="mustOpenHardwareTokenActivationDialog && !isUserAuthenticated"
    [title]="title"
    [accountId]="accountId"
    [username]="username"
    (closePasswordAuthenticationDialog)="closeDialog()"
    (passwordAuthenticated)="onPasswordAuthenticated($event)">
  </wg-ssp-password-authentication>

  <wg-dialog
    *ngIf="mustOpenHardwareTokenActivationDialog && isUserAuthenticated"
    class="custom-hardware-token-activation-dialog"
    [showDialog]="true"
    (dialogClosedEvent)="closeDialog()">
    <div dialog-title>
      {{ messageTranslateKeys.hardware_token_activation_title | translate }}
    </div>
    <div dialog-content>
      <div *ngIf="errorMessage" class="error-message text-center">
        <wg-icon name="attention"></wg-icon>
        <span class="align-middle">{{ errorMessage | translate }}</span>
      </div>
      <wg-ssp-hardware-token-form
        [exhibitionType]="ExhibitionType.DIALOG"
        [(serialNumber)]="serialNumber"
        [(otp)]="otp"
        (serialNumberChange)="cleanErrorMessage()"
        (otpChange)="cleanErrorMessage()">
      </wg-ssp-hardware-token-form>
      <wg-http-error-notification *ngIf="errorMessage"></wg-http-error-notification>
    </div>
    <div dialog-footer>
      <div class="row ui-dialog-buttonpane no-gutters float-right">
        <button class="btn btn-primary " type="button"
        [disabled]="shouldFooterButtonBeDisabled()" (click)="activateHardwareToken()">
          {{ messageTranslateKeys.finish | translate }}
        </button>
      </div>
    </div>
  </wg-dialog>
</div>
