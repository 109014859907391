<wg-forget-authenticator-alert
  [authenticationPolicy]="authenticationPolicy"
  [buttonLabel]="messageTranslateKeys.forgot_token | translate">
</wg-forget-authenticator-alert>
<form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm.valid)">
    <span *ngIf="loginInvalid" class="error-message">
        <wg-icon name="attention"></wg-icon>
        <span class="align-middle" [translate]="errorMessage"></span>
    </span>
    <div class="form-group custom-form-group required" *ngIf="loginByPassword">
        <label for="qrcode" [translate]="messageTranslateKeys.password"></label>
        <wg-hide-show-password>
            <input autofocus type="password" class="input-block-level" autocomplete="off" #password="ngModel" name="loginForm.password" XSSPatternValidator="false"
                [(ngModel)]="otpCode.password" (ngModelChange)="cleanErrorMessage()" required placeholder="{{ messageTranslateKeys.password | translate}}">
        </wg-hide-show-password>
        <show-errors [control]="password" keySuffix="password"></show-errors>
    </div>
    <div class="form-group custom-form-group required">
        <label for="qrcode" [translate]="messageTranslateKeys.otp"></label>
        <input type="text" [autofocus]="!loginByPassword" class="input-block-level form-control" autocomplete="off" #oneTimePassword="ngModel" name="loginForm.oneTimePassword"
            [(ngModel)]="otpCode.oneTimePassword" (ngModelChange)="cleanErrorMessage()" OnlyNumber minlength="6" maxlength="6"
            rangeValidator required placeholder="{{ messageTranslateKeys.otp | translate}}">
        <show-errors [control]="oneTimePassword" keySuffix="otp"></show-errors>
    </div>
    <div class="form-actions">
        <button class="btn btn-primary login-btn" type="submit" [disabled]="loginForm.invalid" [translate]="messageTranslateKeys.finish"></button>
    </div>
</form>
<wg-forgot-password
    *ngIf="loginByPassword && authenticationPolicy.hasForgotPassword"
    [username]="authentication.user.username"
    [accountId]="authentication.accountId"
    (errorResponse)="handleSendPasswordError($event)">
</wg-forgot-password>
<wg-forget-authenticator
    [authenticationPolicy]="authenticationPolicy"
    [authentication]="authentication"
    (loginResponse)="handleLoginSuccess($event)">
</wg-forget-authenticator>
<wg-pending-token-activation
    [hasPendingToken]="authenticationPolicy.pendingToken"
    [authentication]="authentication"
    (authenticationPolicyChange)="updateAuthenticationPolicy()">
</wg-pending-token-activation>
