<div *ngIf="showPendingTokenActivation && !isSspEnabled" class="token-activate">
    <a (click)="openPendingTokenActivationDialog()" class="ft-pending-token-activation-link">
            {{ messageTranslateKeys.pending_token_activation_link | translate }}
    </a>

    <wg-dialog *ngIf="isPendingTokenActivationDialogOpen" [showDialog]="isPendingTokenActivationDialogOpen" class="dialog ft-pending-token-activation-dialog"
        (dialogClosedEvent)="closePendingTokenActivationDialog()">

        <div dialog-title>{{ messageTranslateKeys.pending_token_activation_title | translate }}</div>

        <div dialog-content>
            <form #pendingTokenActivationForm="ngForm" id="idPendingTokenActivationForm"
                (ngSubmit)="generateActivationQrCode()">
                <div *ngIf="errorMessage" class="error-message">
                    <wg-icon name="attention"></wg-icon>
                    <span class="align-middle">{{ errorMessage | translate }}</span>
                </div>

                <div *ngIf="!qrCodeImage">
                    <p>
                        {{ messageTranslateKeys.pending_token_activation_usage | translate }}
                    </p>

                    <div class="form-group required password-group">
                        <label for="idPassword">{{ messageTranslateKeys.password | translate }}</label>
                        <wg-hide-show-password>
                            <input type="password" autofocus required
                                class="form-control ft-pending-token-activation-password" name="password" id="idPassword"
                                [(ngModel)]="password" placeholder="{{ messageTranslateKeys.password | translate}}"
                                autocomplete="off">
                        </wg-hide-show-password>
                        <show-errors [control]="pendingTokenActivationForm.controls.password" keySuffix="password">
                        </show-errors>
                    </div>
                </div>

                <div *ngIf="qrCodeImage">
                  <div *ngIf="isNonMFA; else isMFA">
                    <p>{{ messageTranslateKeys.pending_token_activation_user_non_mfa_message | translate }}</p>
                    <p [innerHTML]="messageTranslateKeys.pending_token_activation_user_non_mfa_action | translate"></p>
                  </div>
                  <ng-template #isMFA>
                    <div class="form-group" *ngIf="isMobileDevice; else isWeb">
                      <p>
                        {{ messageTranslateKeys.pending_token_activation_mobile_link_usage | translate }}
                      </p>
                      <div class="activate-link-container">
                        <a class="btn btn-primary ft-pending-token-activation-link" [href]="mobileActivationLink">
                          {{ messageTranslateKeys.pending_token_activation_mobile_link_activate | translate }}
                        </a>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #isWeb>
                    <div *ngIf="!isNonMFA" class="form-group">
                      <p>
                        {{ messageTranslateKeys.pending_token_activation_qr_code_usage | translate }}
                      </p>
                      <div class="qrcode-box">
                        <img class="qrcode-image ft-pending-token-activation-qrcode" alt="qrCode"
                          [src]="qrCodeImage">
                      </div>
                    </div>
                  </ng-template>
                </div>
            </form>
        </div>

        <div dialog-footer>
            <div class="row ui-dialog-buttonpane no-gutters float-right">
                <button *ngIf="!qrCodeImage" class="btn btn-primary ft-pending-token-activation-generate"
                    form="idPendingTokenActivationForm" [disabled]="pendingTokenActivationForm.invalid" type="submit">
                    {{ messageTranslateKeys.pending_token_activation_button_next | translate }}
                </button>

                <button *ngIf="qrCodeImage" (click)="closePendingTokenActivationDialog()"
                    class="btn btn-primary ft-pending-token-activation-close" type="button">
                    {{ messageTranslateKeys.pending_token_activation_button_close | translate }}
                </button>
            </div>
        </div>
    </wg-dialog>
</div>
